import queryString from "query-string";
import {
    put,
    call,
    takeLatest,
    fork,
    take,
    cancel,
    delay,
    race,
} from "redux-saga/effects";
import { Sagas } from ".";
import {
    ActionsTypes as ReportsActionTypes,
    Actions as ReportsActions,
} from "./actions";
import { ActionTypes as PagesActions } from "../../pages/actions";
import axios from "../../services/axios";

// import config from 'config';
import _ from "lodash";


function* getCSVfile(action) {
    let { payload, type } = action;
    try {

        yield put(ReportsActions.GET_CSV_REQUEST())
        const response = yield call(axios.get,
            `expenses/report?${payload}`, {
            headers: {
                responseType: 'blob',
            }
        })
        var BOM = new Uint8Array([0xEF, 0xBB, 0xBF]);
        const url = window.URL.createObjectURL(new Blob([BOM, response.data]));
        const link = document.createElement('a');
        link.href = url;
        let date = new Date()
        let ddate = date.getDate() +
            "_" + (date.getMonth() + 1) +
            "_" + date.getFullYear();
        link.setAttribute('download', `דו"ח הוצאות ${ddate}.csv`); //or any other extension
        document.body.appendChild(link);
        link.click();



        // FileDownload(data, 'report.csv');

        yield put(ReportsActions.GET_CSV_SUCCESS());
    }
    catch (err) {
        console.error("err.response.data.message",err.response.data.message)
        if (err.response.data.message === 'No expenses found')
            yield put(ReportsActions.GET_CSV_FAILURE("לא נמצאו פניות העונות על סינון זה"));
        else
            yield put(ReportsActions.GET_CSV_FAILURE("אירעה תקלה בלתי צפויה.נסה שנית"))

    }
}


const sagas = [
    takeLatest(PagesActions.EXPORT_CSV_SUBMITTED, getCSVfile),
];
export default sagas;
