import { Box, Button, TextField, CircularProgress, Snackbar, Alert } from "@mui/material"
import styles from "./style"
import theme from "../../themes/default"
import { useFormik } from 'formik';
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";


export default function Login({ loading, onSubmit, error, loggedIn }) {

    const [openSnack, setOpenSnack] = useState(true);
    const navigate = useNavigate()

    useEffect(() => {
        setOpenSnack(true)
    }, [error])

    useEffect(() => {
        if (loggedIn == true)
            navigate("/dashboard");
    }, [loggedIn])

    const validateInputs = (values) => {
        const errors = {};
        if (!values.email) {
            errors.email = 'יש להזין מייל על מנת להתחבר';
        } else if (
            !/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(values.email)

        ) {
            errors.email = 'כתובת מייל אינה תקינה';
        }
        if (!values.password) {
            errors.password = 'יש להזין סיסמא על מנת להתחבר';
        } else if (
            values.password.length < 8
        ) {
            errors.password = 'סיסמא קטנה מ8 תווים';
        }
        return errors;
    }


    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''

        },
        validate: validateInputs,
        onSubmit: (values) => {
            let email = values.email;
            let password = values.password;
            onSubmit({ email, password })
        },
    });

    const handleSnackClose = () => {
        setOpenSnack(false)
    }



    return (
        <>
            <Box sx={styles.wrapper}>
                <Box sx={styles.title}
                >
                    <Box sx={styles.paragraph}>
                        כלל ישראל  חסד ורחמים</Box>
                </Box>
                <Box
                    component="form"
                    onSubmit={formik.handleSubmit}
                    id="loginForm"
                    sx={styles.loginForm}>
                    <Box sx={styles.connect}>
                        התחברות</Box>
                    <Box
                        sx={{ width: "50%", height: "70%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between" }}>
                        <TextField
                            sx={{ ...styles.details }}
                            id="email"
                            name="email"
                            variant="outlined"
                            type="email"
                            placeholder="כתובת מייל"
                            fullWidth
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                        />


                        <TextField
                            sx={{ ...styles.details, pt: "1%" }}
                            id="password"
                            name="password"
                            variant="outlined"
                            type="password"
                            placeholder="סיסמא"
                            fullWidth
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            error={formik.touched.password && Boolean(formik.errors.password)}
                            helperText={formik.touched.password && formik.errors.password}
                        />
                        <>
                            <Button
                                fullWidth
                                variant="contained"
                                form="loginForm"
                                type="submit"
                                sx={styles.submit}
                            >
                                {loading ? <CircularProgress size={26} sx={{ color: theme.palette.text.primary }} /> : "התחבר"}
                            </Button>

                            <Link
                                to="/forgot-Password"
                                style={{
                                    textAlign: "right",
                                    alignContent: "right",
                                    textDecoration: "none",
                                    color: "#000000",
                                    alignSelf: "flex-start",
                                    fontSize: "0.9vw"
                                }}
                            >
                                שכחתי סיסמא
                            </Link>


                        </>
                    </Box>




                </Box>

            </Box >
            {error &&
                (<Snackbar
                    open={openSnack}
                    autoHideDuration={6000}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    onClose={handleSnackClose}>
                    <Alert
                        onClose={handleSnackClose}
                        variant="filled"
                        severity="error"
                        sx={{ width: '100%' }}>
                        הפרטים שהזנת אינם נכונים, יש לוודא שוב את פרטי הכניסה</Alert>

                </Snackbar>)
            }
        </>
    )
}