import React, { useEffect, useState, useRef } from 'react'
import {
    Box, TextField,
    Button,
    Autocomplete,
    FormControl,
    FormHelperText,
    CircularProgress,
    Snackbar, Alert, IconButton
} from '@mui/material'
import { useFormik } from 'formik'
import styles from './style'
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { fetchCities, fetchBankCodes, fetchCategories } from '../../utils/autoComplete'
import logo from '../../images/logo.png'
import SignaturePad from 'react-signature-canvas'
import './styles.css'
import DeleteIcon from '@mui/icons-material/Delete';





export default function InstituteFundForm({ onMount, onSubmit,onEdit,changeTab, isSubmitting, error, submitSuccess,fund }) {

    // assignment
    const [cities, setCities] = useState([])
    const [codes, setCodes] = useState([])
    const [categories, setCategories] = useState([])
    const [selectedCategory, setSelectedCategory] = useState(null)
    const [openSnack, setOpenSnack] = useState(false)
   
    const sigCanvas = useRef({})
    
    // useEffects
    useEffect(() => {
        fetchCities().then(c => setCities(c)).catch(console.error);
        fetchBankCodes().then(c => setCodes(c)).catch(console.error);
        fetchCategories('mosad').then(c => setCategories(c)).catch(console.error);
        changeTab(3);

    }, [])

    useEffect(() => {
        setOpenSnack(true)
    }, [error, submitSuccess])

  
    const clear = () => {
        sigCanvas.current.clear()
    };



    const details = [
        {
            name: "שם עמותה",
            id: "mosadName"
        },
        {
            name: "מס' עמותה",
            id: "mosadNum"
        },
        {
            name: "טלפון",
            id: "phone"
        },
        {
            name: "כתובת מייל",
            id: "email"
        },
        {
            name: "עיר",
            id: "city"
        },
        {
            name: "רחוב",
            id: "street"
        },
        {
            name: "מס' בית",
            id: "houseNo"
        },
        {
            name: "קטגוריה",
            id: "category"
        },
        {
            name: "פרטי המקרה",
            id: "description"
        },
        {
            name: "הערות",
            id: "comments"
        },

    ]

    const bankDetails = [
        {
            name: "בנק",
            id: "bankName",
            width: { xs: "100%", md: "25%" }
        },
        {
            name: "מספר בנק",
            id: "code",
            width: { xs: "100%", md: "13%" }
        },
        {
            name: "מספר חשבון",
            id: "accountNumber",
            width: { xs: "100%", md: "20%" }
        },
        {
            name: "סניף",
            id: "branch",
            width: { xs: "100%", md: "10%" }
        },
        {
            name: 'ע"ש',
            id: "ownerName",
            width: { xs: "100%", md: "20%" }
        },
    ]

    const mosadFiles = [
        {
            name: "טופס בקשה",
            id: "fileRequest",
            width: { xs: "100%", md: "25%" }
        },
        {
            name: "ניהול ספרים",
            id: "fileBookKeepingAuthorization",
            width: { xs: "100%", md: "13%" }
        },
        {
            name: "קובץ ניהול תקין",
            id: "fileProperManagement",
            width: { xs: "100%", md: "20%" }
        },
    ]


    const handleselectedFile = (event) => {
        if (event.target.files.length > 0) {
            const file = URL.createObjectURL(event.target.files[0]);
            formik.setFieldValue(event.target.id, {
                url: file,
                file: event.target.files[0],
                name: event.target.files[0].name,
            })
        }
    }

    const handleSnackClose = () => {
        setOpenSnack(false)
    }

    const validateInputs = (values) => {
        const errors = {};
        if (!values.email) {
            errors.email = 'יש להזין כתובת מייל';
        } else if (
            !/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(values.email)

        ) {
            errors.email = 'כתובת מייל אינה תקינה';
        }
        if (!values.mosadName ) {
            errors.mosadName  = 'יש להזין שם עמותה';
        }
        if (!values.phone) {
            errors.phone = "יש להזין מס' טלפון";
        } else if (!/^[0-9]*$/.test(values.phone)) {
            errors.phone = "מספרים בלבד";
        }
        else if (values.phone.length != 10) {
            errors.phone = "מס' לא תקין";
        }
        if (!values.mosadNum ) {
            errors.mosadNum  = "יש להזין מספר עמותה";
        } else if (!/^[0-9]*$/.test(values.mosadNum )) {
            errors.mosadNum  = "מספרים בלבד";
        }
        // else if (values.tz_num.length != 9) {
        //     errors.tz_num = "מס' לא תקין";
        // }
        if (!values.city) {
            errors.city = "יש לבחור עיר";
        }
        if (!values.street) {
            errors.street = "יש להזין שם רחוב";
        }
        if (!values.houseNo) {
            errors.houseNo = "יש  להזין מס' בית";
        }
        else if (!/^[0-9]*$/.test(values.houseNo)) {
            errors.houseNo = "מספרים בלבד";
        }
        if (!values.category) {
            errors.category = "יש לבחור קטגורית תמיכה";
        }
        if (!values.description) {
            errors.description = "יש להזין את פרטי המקרה";
        }
        if (!values.bankName) {
            errors.bankName = "יש להזין שם בנק";
        }
        if (!values.code) {
            errors.code = "יש להזין קוד בנק";
        }
        if (!values.accountNumber) {
            errors.accountNumber = "יש להזין מס' חשבון";
        }
        else if (!/^[0-9]*$/.test(values.accountNumber)) {
            errors.accountNumber = "מספרים בלבד";
        }
        if (!values.branch) {
            errors.branch = "יש להזין מס' סניף";
        }
        else if (!/^[0-9]*$/.test(values.branch)) {
            errors.branch = "מספרים בלבד";
        }
        if (!values.ownerName) {
            errors.ownerName = "יש להזין שם בעל החשבון' ";
        }
        if (!values.fileRequest) {
            errors.fileRequest = "לא נבחר קובץ";
        }
        if (!values.fileBookKeepingAuthorization) {
            errors.fileBookKeepingAuthorization = "לא נבחר קובץ";
        }
        if (!values.fileProperManagement) {
            errors.fileProperManagement = "לא נבחר קובץ";
        }
        if (!values.submitDate) {
            errors.submitDate = "בחר תאריך";
        }
        if (selectedCategory && selectedCategory.fileTypes) {
            selectedCategory.fileTypes.forEach(function (item) {
                if (!values[item.guid])
                    errors[item.guid] = "לא נבחר קובץ"
            })
        }
        return errors;
    }


    const formik = useFormik({
        initialValues:{
            mosadName : fund?fund.mosadName:'',
            email: fund?fund.email:'',
            phone: fund?fund.phone:'',
            mosadNum : fund?fund.mosadNum:'',
            city: fund?fund.city:null,
            street: fund?fund.street:null,
            houseNo: fund?fund.houseNo:'',
            category: fund?fund.category:null,
            description: fund?fund.description:'',
            comments:fund?fund.comments:'',
            bankName: fund?fund.bankName:null,
            code: fund?fund.bankAccount.code:'',
            branch: fund?fund.bankAccount.branch:'',
            accountNumber: fund?fund.bankAccount.accountNumber:'',
            ownerName: fund?fund.bankAccount.ownerName:'',
            submitDate: fund?fund.submitDate:'',
            fileRequest: fund?fund.fileRequest:'',
            fileBookKeepingAuthorization:fund?fund.fileBookKeepingAuthorization:'',
            fileProperManagement: fund?fund.fileProperManagement:''    
        },
        validate: validateInputs,
        onSubmit: (values) => {

            let files = {}
            if (selectedCategory && selectedCategory.fileTypes) {
                selectedCategory.fileTypes.forEach(function (item) {
                    files = { ...files, [item.guid]: values[item.guid] }

                })
            }


            let details = {
                category: values.category.id,
                description: values.description,
                comments: values.comments,
                mosadName : values.mosadName ,
                phone: values.phone,
                email: values.email,
                mosadNum : values.mosadNum ,
                city: values.city['שם_ישוב'],
                street: values.street,
                houseNo: Number(values.houseNo),
                bankAccount: {
                    code: values.code,
                    branch: values.branch,
                    accountNumber: values.accountNumber,
                    ownerName: values.ownerName
                },
                fileRequest: values.fileRequest,
                fileBookKeepingAuthorization: values.fileBookKeepingAuthorization,
                fileProperManagement: values.fileProperManagement,
                files: files
            }
            onSubmit(details)
        },
    });
    useEffect(() => {
        if (formik.values.bankName)
            formik.setFieldValue("code", formik.values.bankName.code);
        else {
            formik.setFieldValue("code", "");
        }
    }, [formik.values.bankName])

    return (
        <>
            <Box sx={styles.main}>
               <Box
                    component="form"
                    onSubmit={formik.handleSubmit}
                    id="applicationForm"
                    sx={styles.form}>
                    <Box sx={{ display: "flex", width: "100%", justifyContent: "flex-start", alignItems: "center", mb: 2 }}>
                        <Box
                            component="img"
                            src={logo}
                            alt="logo"
                            sx={{ maxWidth: { md: "7vw", xs: "30px" } }}
                        />

                    </Box>

                 <Box sx={styles.title}>
                        יצירת קרן-כלל ישראל חסד ורחמים
                    </Box>
                    <Box sx={styles.subTitle}>
                        עבור מוסד/ארגון
                    </Box>
                    <Box sx={styles.formDetails}>
                        {details.map((item) => {
                            if (item.id === "city")
                                return (< React.Fragment key={item.id} >
                                    <FormControl sx={styles.Autodetails}>
                                        <Autocomplete
                                            id={item.id}
                                            name={item.id}
                                            value={formik.values[item.id]}
                                            onChange={(e, value) => {
                                                formik.setFieldValue("city", value)

                                            }}
                                            options={cities}
                                            loading={true}
                                            loadingText="אין אופציות"
                                            getOptionLabel={type => type['שם_ישוב']}
                                            isOptionEqualToValue={(option, value) => option._id === value._id}
                                            renderInput={(params) => <TextField {...params}
                                                placeholder={item.name}
                                                sx={styles.placeholder}
                                                error={formik.touched[item.id] && Boolean(formik.errors[item.id])}
                                                helperText={formik.touched[item.id] && formik.errors[item.id]}
                                            />}
                                        />
                                    </FormControl>
                                </React.Fragment>);
                            else if (item.id === "category")
                                return (< React.Fragment key={item.id} >
                                    <FormControl sx={styles.Autodetails}>
                                        <Autocomplete
                                            id={item.id}
                                            name={item.id}
                                            value={formik.values[item.id]}
                                            onChange={(e, value) => {
                                                formik.setFieldValue("category", value)
                                                setSelectedCategory(value);
                                            }}
                                            options={categories}
                                            loading={true}
                                            loadingText="אין אופציות"
                                            getOptionLabel={type => type['subject']}
                                            isOptionEqualToValue={(option, value) => option._id === value._id}
                                            renderInput={(params) => <TextField {...params}
                                                placeholder={item.name}
                                                sx={styles.placeholder}
                                                error={formik.touched[item.id] && Boolean(formik.errors[item.id])}
                                                helperText={formik.touched[item.id] && formik.errors[item.id]}
                                            />}
                                        />
                                    </FormControl>
                                </React.Fragment>);

                            return (
                                <TextField
                                    key={item.id}
                                    sx={{
                                        ...styles.details,
                                        mt: 6,
                                        width: { xs: "100%", md: item.id === "description" || item.id == "comments" ? "100%" : "23%" }
                                    }}
                                    rows={item.id === "description" || item.id === "comments" ? 5 : 1}
                                    multiline
                                    id={item.id}
                                    name={item.id}
                                    variant="outlined"
                                    type={item.id}
                                    // placeholder={item.name}
                                    label={item.name}
                                    // InputLabelProps={{ shrink: true, sx: { fontSize: "1.5vw" } }}
                                    fullWidth
                                    value={formik.values[item.id]}
                                    onChange={formik.handleChange}
                                    error={formik.touched[item.id] && Boolean(formik.errors[item.id])}
                                    helperText={formik.touched[item.id] && formik.errors[item.id]}
                                />
                            )
                        })}
                        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                            <Box component="p" sx={styles.p}>
                                {"קבצים"}
                            </Box>


                            {selectedCategory && selectedCategory.fileTypes && <Box component="p" sx={{ mt: 0, color: "red" }}>
                                סוגי קבצים מותרים: jpeg, jpg, png, pdf
                            </Box>}
                        </Box>
                    </Box>
                    <Box sx={styles.filesBox}>
                        {selectedCategory ? selectedCategory.fileTypes.map((item) => (
                            <Box sx={{ display: "flex", mb: 1, flexDirection: "column", width: "100%" }}>
                                <input
                                    key={item.guid}
                                    type="file"
                                    name="file"
                                    id={item.guid}
                                    onChange={handleselectedFile}
                                    style={{ display: "none" }}
                                />
                                <p>{item.label}</p>
                                <label htmlFor={item.guid}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        component="span"
                                        sx={{ width: { xs: "100%", md: "15vw" } }}
                                        endIcon={<UploadFileIcon />}
                                    >
                                        {formik.values[item.guid] ? formik.values[item.guid].name : "בחר קובץ"}
                                    </Button>
                                </label>


                                {
                                    Boolean(formik.errors[item.guid]) &&
                                    <FormHelperText error={true}>{formik.errors[item.guid]} </FormHelperText>}

                            </Box>
                        )) :
                            <Box
                                component="p"
                            >בחר קטגוריה להצגת הקבצים הנדרשים
                            </Box>}

                    </Box>
                    <Box sx={styles.bankBox}>
                        <Box
                            component="p"
                            sx={styles.pBank}
                        >פרטי חשבון בנק להעברת הכספים
                        </Box>

                        <Box sx={styles.bankDetails}>
                            {bankDetails.map((item) => {
                                if (item.id === "bankName")
                                    return (< React.Fragment key={item.id} >
                                        <FormControl
                                            sx={{ ...styles.Autodetails, width: item.width }}>
                                            <Autocomplete
                                                id={item.id}
                                                name={item.id}
                                                value={formik.values[item.id]}
                                                onChange={(e, value) => {
                                                    formik.setFieldValue("bankName", value)
                                                }}
                                                options={codes}
                                                loading={true}
                                                loadingText="אין אופציות"
                                                getOptionLabel={type => type.name}
                                                isOptionEqualToValue={(option, value) => option.code === value.code}
                                                renderInput={(params) => <TextField {...params}
                                                    placeholder={item.name}
                                                    sx={styles.placeholder}
                                                    error={formik.touched[item.id] && Boolean(formik.errors[item.id])}
                                                    helperText={formik.touched[item.id] && formik.errors[item.id]}
                                                />}
                                            />
                                        </FormControl>
                                    </React.Fragment>);
                                return <TextField
                                    key={item.id}
                                    sx={{
                                        ...styles.details,
                                        mt: 6,
                                        width: item.width
                                    }}
                                    id={item.id}
                                    name={item.id}
                                    variant="outlined"
                                    type={item.id}

                                    // placeholder={item.name}
                                    label={item.name}
                                    // InputLabelProps={{ shrink: true, sx: { fontSize: "1.5vw" } }}
                                    fullWidth
                                    value={formik.values[item.id]}
                                    onChange={item.id === "code" ? {} : formik.handleChange}
                                    error={formik.touched[item.id] && Boolean(formik.errors[item.id])}
                                    helperText={formik.touched[item.id] && formik.errors[item.id]}
                                />
                            })}
                        </Box>


                    </Box>
                    <Box sx={styles.signBox}>
                        <Box component="p">בחתימתי זו הנני מצהיר על אמיתות הנתונים</Box>
                        <SignaturePad
                            ref={sigCanvas}
                            canvasProps={{ className: "sigContainer" }}
                        />
                        <Box sx={{ dispaly: "flex" }}>
                            <IconButton
                                disableRipple
                                onClick={clear}
                            >
                                <DeleteIcon color="selected" />
                            </IconButton>
                        </Box>
                    </Box>
                    <Box sx={styles.filesBox}>
                        {mosadFiles.map((item) => (
                            <Box sx={{ display: "flex", mb: 1, flexDirection: "column", width: "100%" }}>
                                <input
                                    key={item.id}
                                    type="file"
                                    name="file"
                                    id={item.id}
                                    onChange={handleselectedFile}
                                    style={{ display: "none" }}
                                />
                                <p>{item.name}</p>
                                <label htmlFor={item.id}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        component="span"
                                        sx={{ width: { xs: "100%", md: "15vw" } }}
                                        endIcon={<UploadFileIcon />}
                                    >
                                        {formik.values[item.id] ? formik.values[item.id].name : "בחר קובץ"}
                                    </Button>
                                </label>


                                {formik.touched[item.id] && Boolean(formik.errors[item.id]) &&
                                    <FormHelperText error={true}>{formik.errors[item.id]} </FormHelperText>}

                            </Box>
                        ))}


                    </Box>
                    <Box sx={styles.submit}>
                        <TextField
                            sx={{
                                ...styles.details,
                                mb: { xs: 2, md: 0 }
                            }}
                            id="submitDate"
                            name="submitDate"
                            variant="outlined"
                            type="date"

                            // placeholder={item.name}
                            label="תאריך"
                            InputLabelProps={{ shrink: true }}

                            value={formik.values.submitDate}
                            onChange={formik.handleChange}
                            error={formik.touched.submitDate && Boolean(formik.errors.submitDate)}
                            helperText={formik.touched.submitDate && formik.errors.submitDate}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            fullWidth
                            form="applicationForm"
                            sx={styles.sButton}
                        >
                            {isSubmitting ? <CircularProgress size={26} sx={{ color: "#ffffff" }} />:"שלח"}</Button>
                    </Box>

                </Box>

            </Box >
            {
                error &&
                (<Snackbar
                    open={openSnack}
                    autoHideDuration={6000}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    onClose={handleSnackClose}>
                    <Alert
                        onClose={handleSnackClose}
                        variant="filled"
                        severity="error"
                        sx={{ width: '100%' }}>
                        אירעה שגיאה.נסה שוב
                    </Alert>

                </Snackbar>)}

            {submitSuccess && <Snackbar
                open={openSnack}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }}
                onClose={handleSnackClose}>
                <Alert
                    onClose={handleSnackClose}
                    variant="filled"
                    severity="success"
                    sx={{ width: '100%' }}>
                    קרן נוצרה בהצלחה!
                </Alert>
            </Snackbar>}
        </>
    )
}