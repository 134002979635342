import { put, call, takeLatest } from "redux-saga/effects";
import { Sagas } from ".";
import {
  ActionsTypes as categotyActionTypes,
  Actions as expensesActions,
} from "./actions";
import { ActionTypes as PagesActions } from "../../pages/actions";
import axios from "../../services/axios";
import _ from "lodash";

function* getActiveCategories(action) {
  try {
    yield put(expensesActions.ACTIVE_CATEGORY_REQUEST());
    const { data } = yield call(axios.get, `/inquiry-categories`);
    yield put(expensesActions.ACTIVE_CATEGORY_SUCCESS(data));
  } catch (err) {
    yield put(expensesActions.ACTIVE_CATEGORY_FAILURE());
  }
}

function* getAllCategories(action) {
  try {
    yield put(expensesActions.ALL_CATEGORY_REQUEST());
    const { data } = yield call(axios.get, `/fund-categories/all`);
    yield put(expensesActions.ALL_CATEGORY_SUCCESS(data));
  } catch (err) {
    yield put(expensesActions.ALL_CATEGORY_FAILURE());
  }
}

function* getFundExpenses(action) {
  let { payload } = action;
  try {
    yield put(expensesActions.GET_FUND_EXPENSES_REQUEST());
    const { data } = yield call(
      axios.get,
      `/expenses/?page=${payload.page}&take=10&status[]=masav&status[]=draft&status[]=finished&fundId=${payload.id}`
    );
    yield put(expensesActions.GET_FUND_EXPENSES_SUCCESS(data));
  } catch (err) {
    yield put(expensesActions.GET_FUND_EXPENSES_FAILURE());
  }
}
function* deleteExpense(action) {
  let { payload } = action;
  try {
    yield put(expensesActions.DELETE_EXPENSES_REQUEST());
    yield call(axios.delete, `/expenses/${payload}`);
    yield put(expensesActions.DELETE_EXPENSES_SUCCESS());
  } catch (err) {
    yield put(expensesActions.DELETE_EXPENSES_FAILURE());
  }
}
function* deleteFund(action) {
  let { payload } = action;
  try {
    yield put(expensesActions.DELETE_FUND_REQUEST());
    yield call(axios.delete, `/funds/${payload}`);
    yield put(expensesActions.DELETE_FUND_SUCCESS());
  } catch (err) {
    yield put(expensesActions.DELETE_FUND_FAILURE());
  }
}

function* updateExpenseStatus(action) {
  let { payload } = action;
  try {
    yield put(expensesActions.UPDATE_EXPENSE_REQUEST());
    const { data } = yield call(axios.put, `/expenses/update/${payload}`);
    yield put(expensesActions.UPDATE_EXPENSE_SUCCESS(data));
  } catch (err) {
    yield put(expensesActions.UPDATE_EXPENSE_FAILURE());
  }
}

function* updateExpense(action) {
  let { payload } = action;
  console.log("payload : ", payload);
  try {
    yield put(expensesActions.CREATE_EXPENSE_REQUEST());
    const { data } = yield call(
      axios.put,
      `/expenses/update-expense/${payload.id}`,
      {
        amount: payload.amount,
        // status: payload.status,
        date: payload.date,
      }
    );
    yield put(expensesActions.CREATE_EXPENSE_SUCCESS(data));
  } catch (err) {
    yield put(expensesActions.CREATE_EXPENSE_FAILURE());
  }
}

function* createExpense(action) {
  let { payload } = action;
  try {
    yield put(expensesActions.CREATE_EXPENSE_REQUEST());
    const { data } = yield call(axios.put, `/expenses/create/${payload.id}`, {
      amount: payload.amount,
      status:
        payload.date < new Date().toISOString().split("T")[0]
          ? "finished"
          : payload.status,
      date: payload.date,
    });
    yield put(expensesActions.CREATE_EXPENSE_SUCCESS(data));
  } catch (err) {
    yield put(expensesActions.CREATE_EXPENSE_FAILURE());
  }
}

const sagas = [
  takeLatest(PagesActions.CREATE_EXPENSE_SUBMITTED, createExpense),
  takeLatest(PagesActions.UPDATE_EXPENSE_STATUS, updateExpenseStatus),
  takeLatest(PagesActions.UPDATE_EXPENSE_SUBMITTED, updateExpense),
  takeLatest(PagesActions.GET_FUND_EXPENSES, getFundExpenses),
  takeLatest(PagesActions.DELETE_EXPENSE, deleteExpense),
  takeLatest(PagesActions.DELETE_FUND, deleteFund),
  // takeLatest(PagesActions.EDIT_CATEGORY_FORM_SUBMITTED, editCategory),
];
export default sagas;
