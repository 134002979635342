import { createSelector } from 'reselect'

export const sliceSelector = (state) => state.expenses;
export const isLoading = createSelector(sliceSelector, slice => slice.loading);
export const error = createSelector(sliceSelector, slice => slice.error)
export const submitSuccess = createSelector(sliceSelector, slice => slice.submitSuccess)
export const filter = createSelector(sliceSelector, slice => slice.filter)
export const expenses = createSelector(sliceSelector, slice => slice.expenses)
export const expense = createSelector(sliceSelector, slice => slice.expense)
export const isSubmitting = createSelector(sliceSelector, slice => slice.isSubmitting)


