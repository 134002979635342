import { createActionCreators, createActionTypes } from "../utils/reduxUtils";

const allActions = [
  "ACTIVE_TAB_CHANGED",

  "FORGOT_PASSWORD_PAGE_MOUNTED",
  "LOGIN_FORM_SUBMITTED",

  "REGISTER_FORM_SUBMITTED",
  "FORGOT_PASSWORD_FORM_SUBMITTED",
  "RESET_PASSWORD_SUBMITTED",
  "SIDEBAR_LOGOUT_REQUESTED",
  "REGISTER_PAGE_MOUNTED",

  "APPLICATION_FORM_MOUNTED",
  "INSTITUTE_APPLICATION_FORM_SUBMITTED",
  "APPLICATION_FORM_SUBMITTED",

  "USERS_PAGE_MOUNTED",
  "FILTER_SEARCH_USERS_SUBMITTED",
  "FILTER_SEARCH_USERS_CLEARED",

  "APPLICATIONS_PAGE_MOUNTED",
  "FILTER_SEARCH_APPLICATIONS_SUBMITTED",
  "FILTER_SEARCH_APPLICATIONS_CLEARED",

  "RESET_PASSWORD_FORM_SUBMITTED",
  "RESET_PASSWORD_PAGE_MOUNTED",

  "FUNDS_PAGE_MOUNTED",
  "FILTER_SEARCH_FUNDS_SUBMITTED",
  "FILTER_SEARCH_FUNDS_CLEARED",

  "SINGLE_USER_PAGE_MOUNTED",
  "EDIT_USER_SUBMITTED",
  "DELETE_USER_SUBMITTED",

  "SINGLE_FUND_FORM_MOUNTED",

  "REPORTS_PAGE_MOUNTED",
  "EXPORT_CSV_SUBMITTED",

  "SINGLE_APPLICATION_PAGE_MOUNTED",
  "EDIT_APPLICATION_FORM_SUBMITTED",

  "CATEGORIES_PAGE_MOUNTED",

  "SINGLE_CATEGORY_PAGE_MOUNTED",
  "EDIT_CATEGORY_FORM_SUBMITTED",
  "GET_CATEGORY_REQUEST",
  "NEW_CATEGORY_SUBMITTED",

  "PENDING_MASAV_PAGE_MOUNTED",
  "EXPORT_MASAV_SUBMITTED",

  "UPDATE_EXPENSE_SUBMITTED",
  "CREATE_EXPENSE_SUBMITTED",
  "GET_FUND_EXPENSES",
  "SINGLE_FUND_PAGE_MOUNTED",
  "EDIT_FUND_FORM_SUBMITTED",
  "GET_SINGLE_FUND",
  "RESET_FUND_DATA",

  "UPDATE_EXPENSE_STATUS",
  "DELETE_EXPENSE",

  "DASHBOARD_PAGE_MOUNTED",
  "DASHBOARD_NEW_YEAR_SUBMITTED",

  "DELETE_FUND",

  "SET_SUBMIT_SUCCESS",
];

export const ActionTypes = createActionTypes(allActions);
export const Actions = createActionCreators(allActions);
