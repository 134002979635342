import Applications from "../../components/applications/applicationPageRedux"
import { useDispatch } from "react-redux"
import { Actions } from "../actions"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import * as ExpensesSelectors from '../../modules/expenses/selectors'

export default function PendingExpenses() {
    const dispatch = useDispatch()
    const submitSuccess=useSelector(ExpensesSelectors.submitSuccess)

    useEffect(() => {
        dispatch(Actions.ACTIVE_TAB_CHANGED(4))
    }, [dispatch])
    return (
        <Applications status="draft" submitSuccess={submitSuccess}/>
    )

}