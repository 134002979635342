import { ActionsTypes } from "./actions";
import { ActionTypes as PageActionsTypes } from "../../pages/actions";

const expensesReducer = (
  state = {
    error: false,
    loading: false,
    submitSuccess: false,
    expenses: [],
    filter: "",
    expense: null,
    isSubmitting: false,
  },
  action
) => {
  let newState;
  let { payload, type } = action;
  switch (type) {
    case ActionsTypes.UPDATE_EXPENSE_REQUEST:
      newState = {
        ...state,
        isSubmitting: true,
        error: false,
        submitSuccess: false,
      };
      break;
    case ActionsTypes.UPDATE_EXPENSE_SUCCESS:
      newState = {
        ...state,
        isSubmitting: false,
        error: false,
        submitSuccess: true,
      };
      break;
    case ActionsTypes.UPDATE_EXPENSE_FAILURE:
      newState = {
        ...state,
        isSubmitting: false,
        error: true,
        submitSuccess: false,
      };
      break;
    case PageActionsTypes.CREATE_EXPENSE_REQUEST:
      newState = {
        ...state,
        isSubmitting: true,
        error: false,
        submitSuccess: false,
      };
      break;
    case ActionsTypes.CREATE_EXPENSE_SUCCESS:
      newState = {
        ...state,
        isSubmitting: false,
        error: false,
        submitSuccess: true,
      };
      break;
    case ActionsTypes.CREATE_EXPENSE_FAILURE:
      newState = {
        ...state,
        isSubmitting: false,
        error: true,
        submitSuccess: false,
      };
      break;
    case ActionsTypes.GET_FUND_EXPENSES_REQUEST:
      newState = { ...state, loading: true, error: false, expenses: null };
      break;
    case ActionsTypes.GET_FUND_EXPENSES_SUCCESS:
      newState = { ...state, loading: false, expenses: payload };
      break;
    case ActionsTypes.GET_FUND_EXPENSES_FAILURE:
      newState = { ...state, loading: false, error: true };
      break;
    case PageActionsTypes.FILTER_SEARCH_APPLICATIONS_SUBMITTED:
      newState = { ...state, filter: `&search=${payload}` };
      break;
    case PageActionsTypes.FILTER_SEARCH_APPLICATIONS_CLEARED:
      newState = { ...state, filter: "" };
      break;
    case ActionsTypes.SINGLE_APPLICATION_REQUEST:
      newState = {
        ...state,
        loading: true,
        error: false,
        application: null,
        submitSuccess: false,
        isSubmitting: false,
      };
      break;
    case ActionsTypes.SINGLE_APPLICATION_SUCCESS:
      newState = { ...state, loading: false, application: payload };
      break;
    case ActionsTypes.SINGLE_APPLICATION_FAILURE:
      newState = { ...state, loading: false };
      break;
    case ActionsTypes.EDIT_APPLICATION_REQUEST:
      newState = {
        ...state,
        isSubmitting: true,
        submitSuccess: false,
        error: false,
      };
      break;
    case ActionsTypes.EDIT_APPLICATION_SUCCESS:
      newState = {
        ...state,
        isSubmitting: false,
        submitSuccess: true,
        error: false,
      };
      break;
    case ActionsTypes.EDIT_APPLICATION_FAILURE:
      newState = {
        ...state,
        isSubmitting: false,
        submitSuccess: false,
        error: true,
      };
      break;
    case ActionsTypes.EXPORT_MASAV_REQUEST:
      newState = {
        ...state,
        isSubmitting: true,
        submitSuccess: false,
        error: false,
      };
      break;
    case ActionsTypes.EXPORT_MASAV_SUCCESS:
      newState = { ...state, isSubmitting: false, error: false };
      break;
    case ActionsTypes.EXPORT_MASAV_FAILURE:
      newState = { ...state, isSubmitting: false, error: true };
      break;
    case PageActionsTypes.PENDING_MASAV_PAGE_MOUNTED:
      newState = { ...state, isSubmitting: false, error: false };
      break;
    case ActionsTypes.SET_SUBMIT_SUCCESS:
      newState = { ...state, submitSuccess: false };
      break;
    case ActionsTypes.DELETE_EXPENSES_SUCCESS:
      newState = { ...state, submitSuccess: true };
      break;

    default:
      newState = state;
  }
  return newState;
};
export default expensesReducer;
