import { connect } from "../../utils/reduxUtils";
import Component from "./Funds";
import { Actions } from "../actions";
import * as FundsSelector from "../../modules/funds/selectors";

const mapDispatchToProps = {
  onMount: Actions.FUNDS_PAGE_MOUNTED,
  changeTab: Actions.ACTIVE_TAB_CHANGED,
  submitSearch: Actions.FILTER_SEARCH_FUNDS_SUBMITTED,
  clearSearch: Actions.FILTER_SEARCH_FUNDS_CLEARED,
  onDeleteFund: Actions.DELETE_FUND,
};

const mapStateToProps = (state) => {
  return {
    filter: FundsSelector.filter(state),
    data: FundsSelector.funds(state),
    loading: FundsSelector.isLoading(state),
    submitSuccess: FundsSelector.submitSuccess(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
