import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import {
  TextField,
  Box,
  FormControl,
  InputLabel,
  Alert,
  Snackbar,
} from "@mui/material";
import { min } from "moment/moment";
import { useEffect, useState } from "react";
import styles from "./style";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CreateExpense({
  id,
  open,
  setOpen,
  onSubmit,
  onUpdate,
  error,
  loading,
  submitSuccess,
  isSubmitting,
  amount,
  date,
  update,
  expenseId,
}) {
  const handleClose = () => {
    setOpen(false);
    amountRef.current.value = "";
    dateRef.current.value = "";
  };

  const [label, setLlabel] = React.useState("סכום");
  const [isfocused, setIsfocused] = React.useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const amountRef = React.useRef();
  const dateRef = React.useRef();

  const [selectedDate, setSelectedDate] = React.useState(
    new Date().toISOString().split("T")[0]
  );
  const [disableMasav, setDisableMasav] = React.useState(false);

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
    // if the date is lower than today, set disabled to true
    if (event.target.value < new Date().toISOString().split("T")[0]) {
      setDisableMasav(true);
    } else {
      setDisableMasav(false);
    }
  };

  function convertDateFormat(originalDate) {
    // Split the original date string into components
    const dateComponents = originalDate.split(".");
    const day = dateComponents[0];
    const month = String(dateComponents[1]).padStart(2, "0"); // Add leading zero if needed
    const year = dateComponents[2];
    // console.log(`${year}-${month}-${day}`);
    return `${year}-${month}-${day}`;
  }

  const handleSnackClose = () => {
    setOpenSnack(false);
  };

  useEffect(() => {
    if (open) {
      if (amount) amountRef.current.value = amount;
      if (date) {
        console.log(date);
        console.log(update);
        if (update)
          dateRef.current.value = new Date(date).toISOString().split("T")[0];
        // dateRef.current.value = convertDateFormat(date);
        else dateRef.current.value = new Date().toISOString().split("T")[0];
        // console.log(dateRef.current.value);
      }
      if (update) {
        setIsfocused(true);
        setLlabel("");
      } else {
        setIsfocused(false);
        setLlabel("סכום");
      }
    }
  }, [open]);

  function handleFocus() {
    if (update) {
      setLlabel("סכום");
      setIsfocused(false);
    }
  }

  function handleBlur() {
    if (update) {
      setLlabel("");
      setIsfocused(true);
    }
  }

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        {update ? (
          <DialogTitle sx={{ fontSize: "1.1rem", textAlign: "center" }}>
            עדכון הוצאה
          </DialogTitle>
        ) : (
          <DialogTitle sx={{ fontSize: "1.1rem", textAlign: "center" }}>
            הוספת הוצאה
          </DialogTitle>
        )}
        <Box sx={{ mx: "auto", my: 2 }}>
          <FormControl variant="outlined" sx={styles.formControl}>
            <InputLabel
              htmlFor="amount-input"
              shrink={true}
              sx={styles.inputLabel}
            >
              {isfocused ? "סכום" : ""}
            </InputLabel>
            <TextField
              label={label}
              type="number"
              inputRef={amountRef}
              inputProps={{ min: 0 }}
              size="small"
              variant="outlined"
              defaultValue=""
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
          </FormControl>
        </Box>
        <Box sx={{ mx: "auto", my: 2 }}>
          <TextField
            sx={{
              mb: { xs: 2, md: 0 },
            }}
            id="submitDate"
            name="submitDate"
            variant="outlined"
            type="date"
            label="תאריך הוצאה"
            InputLabelProps={{ shrink: true }}
            inputRef={dateRef}
            value={selectedDate}
            inputProps={{ max: new Date().toISOString().split("T")[0] }}
            onChange={handleDateChange}
          />
        </Box>
        {update ? (
          <DialogActions>
            <Button
              variant="contained"
              onClick={() => {
                onUpdate({
                  id: expenseId,
                  amount: parseInt(amountRef.current.value),
                  date: dateRef.current.value,
                  status: dateRef.current.status,
                });
                handleClose();
              }}
              sx={{ width: "250px", marginInline: "15px" }}
            >
              עדכן
            </Button>
          </DialogActions>
        ) : (
          <DialogActions>
            <Button
              variant="outlined"
              onClick={() => {
                onSubmit({
                  id: id,
                  amount: parseInt(amountRef.current.value),
                  date: dateRef.current.value,
                  status: "draft",
                });
                handleClose();
              }}
              sx={{ width: "150px" }}
            >
              צור
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                onSubmit({
                  id: id,
                  amount: parseInt(amountRef.current.value),
                  date: dateRef.current.value,
                  status: "masav",
                });
                handleClose();
              }}
              sx={{ width: "150px" }}
              disabled={disableMasav}
            >
              צור והעבר למס"ב
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
}
