import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  TextField,
  Button,
  Autocomplete,
  FormControl,
  FormHelperText,
  CircularProgress,
  Snackbar,
  Alert,
  IconButton,
} from "@mui/material";
import theme from "../../themes/default";
import { useFormik } from "formik";
import styles from "./style";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import {
  fetchCities,
  fetchBankCodes,
  fetchCategories,
} from "../../utils/autoComplete";
import logo from "../../images/logo.png";
import SignaturePad from "react-signature-canvas";
import "./styles.css";
import DeleteIcon from "@mui/icons-material/Delete";

export default function PrivateFundForm({
  onMount,
  onSubmit,
  changeTab,
  isSubmitting,
  error,
  submitSuccess,
}) {
  // assignment
  const [cities, setCities] = useState([]);
  const [codes, setCodes] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [openSnack, setOpenSnack] = useState(false);
  const sigCanvas = useRef({});

  // useEffects
  useEffect(() => {
    fetchCities()
      .then((c) => setCities(c))
      .catch(console.error);
    fetchBankCodes()
      .then((c) => setCodes(c))
      .catch(console.error);
    fetchCategories("private")
      .then((c) => setCategories(c))
      .catch(console.error);
    changeTab(3);
  }, []);

  useEffect(() => {
    setOpenSnack(true);
  }, [error, submitSuccess]);

  useEffect(() => {
    onMount();
  }, []);

  const clear = () => {
    sigCanvas.current.clear();
  };

  const details = [
    {
      name: "שם פרטי   |   first name ",
      id: "firstName",
    },
    {
      name: "שם משפחה   |   last name",
      id: "lastName",
    },
    {
      name: "ת.ז   |    passport id",
      id: "tzNum",
    },
    {
      name: "טלפון   |    phone",
      id: "phone",
    },
    {
      name: "כתובת מייל   |    E-mail",
      id: "email",
    },
    {
      name: "עיר   |    city",
      id: "city",
    },
    {
      name: "רחוב    |    street",
      id: "street",
    },
    {
      name: "מס' בית   |    house number",
      id: "houseNo",
    },
    {
      name: "קטגוריה | category",
      id: "category",
    },
    {
      name: "פרטי המקרה | description",
      id: "description",
    },
    {
      name: "הערות | comments",
      id: "comments",
    },
  ];

  const bankDetails = [
    {
      name: "בנק | bank",
      id: "bankName",
      width: { xs: "100%", md: "25%" },
    },
    {
      name: "מספר בנק | bank number",
      id: "code",
      width: { xs: "100%", md: "13%" },
    },
    {
      name: "מספר חשבון | account number",
      id: "accountNumber",
      width: { xs: "100%", md: "20%" },
    },
    {
      name: "סניף |  branch",
      id: "branch",
      width: { xs: "100%", md: "10%" },
    },
    {
      name: 'ע"ש | name',
      id: "ownerName",
      width: { xs: "100%", md: "20%" },
    },
  ];

  const handleselectedFile = (event) => {
    if (event.target.files.length > 0) {
      const file = URL.createObjectURL(event.target.files[0]);
      formik.setFieldValue(event.target.id, {
        url: file,
        file: event.target.files[0],
        name: event.target.files[0].name,
      });
    }
  };

  const handleSnackClose = () => {
    setOpenSnack(false);
  };

  const validateInputs = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = "יש להזין כתובת מייל";
    } else if (!/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(values.email)) {
      errors.email = "כתובת מייל אינה תקינה";
    }
    if (!values.firstName) {
      errors.firstName = "יש להזין שם פרטי";
    }
    if (!values.lastName) {
      errors.lastName = "יש להזין שם משפחה";
    }
    if (!values.phone) {
      errors.phone = "יש להזין מס' טלפון";
    } else if (!/^[0-9]*$/.test(values.phone)) {
      errors.phone = "מספרים בלבד";
    } else if (values.phone.length != 10) {
      errors.phone = "מס' לא תקין";
    }
    if (!values.tzNum) {
      errors.tzNum = "יש להזין תעודת זהות";
    } else if (!/^[0-9]*$/.test(values.tzNum)) {
      errors.tzNum = "מספרים בלבד";
    } else if (values.tzNum.length != 9) {
      errors.tzNum = "מס' לא תקין";
    }
    if (!values.city) {
      errors.city = "יש לבחור עיר";
    }
    if (!values.street) {
      errors.street = "יש להזין שם רחוב";
    }
    if (!values.houseNo) {
      errors.houseNo = "יש  להזין מס' בית";
    } else if (!/^[0-9]*$/.test(values.houseNo)) {
      errors.houseNo = "מספרים בלבד";
    }
    if (!values.category) {
      errors.category = "יש לבחור קטגורית תמיכה";
    }
    if (!values.description) {
      errors.description = "יש להזין את פרטי המקרה";
    }
    if (!values.bankName) {
      errors.bankName = "יש להזין שם בנק";
    }
    if (!values.code) {
      errors.code = "יש להזין קוד בנק";
    }
    if (!values.accountNumber) {
      errors.accountNumber = "יש להזין מס' חשבון";
    } else if (!/^[0-9]*$/.test(values.accountNumber)) {
      errors.accountNumber = "מספרים בלבד";
    }
    if (!values.branch) {
      errors.branch = "יש להזין מס' סניף";
    } else if (!/^[0-9]*$/.test(values.branch)) {
      errors.branch = "מספרים בלבד";
    }
    if (!values.ownerName) {
      errors.ownerName = "יש להזין שם בעל החשבון' ";
    }
    if (selectedCategory && selectedCategory.fileTypes) {
      selectedCategory.fileTypes.forEach(function (item) {
        if (!values[item.guid]) errors[item.guid] = "לא נבחר קובץ";
      });
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      tzNum: "",
      city: null,
      street: "",
      houseNo: "",
      category: null,
      description: "",
      bankName: null,
      code: "",
      branch: "",
      accountNumber: "",
      ownerName: "",
      submitDate: "",
    },
    validate: validateInputs,
    onSubmit: (values) => {
      let files = {};
      if (selectedCategory && selectedCategory.fileTypes) {
        selectedCategory.fileTypes.forEach(function (item) {
          files = { ...files, [item.guid]: values[item.guid] };
        });
      }

      let details = {
        category: values.category.id,
        description: values.description,
        comments: values.comments,
        firstName: values.firstName,
        lastName: values.lastName,
        phone: values.phone,
        email: values.email,
        tzNum: values.tzNum,
        city: values.city["שם_ישוב"],
        street: values.street,
        houseNo: Number(values.houseNo),
        bankAccount: {
          code: values.code,
          branch: values.branch,
          accountNumber: values.accountNumber,
          ownerName: values.ownerName,
        },
        files: files,
      };
      onSubmit(details);
    },
  });

  useEffect(() => {
    if (formik.values.bankName)
      formik.setFieldValue("code", formik.values.bankName.code);
    else {
      formik.setFieldValue("code", "");
    }
  }, [formik.values.bankName]);

  return (
    <>
      <Box sx={styles.main}>
        <Box
          component="form"
          onSubmit={formik.handleSubmit}
          id="applicationForm"
          sx={styles.form}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-start",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Box
              component="img"
              src={logo}
              alt="logo"
              sx={{ maxWidth: { md: "7vw", xs: "30px" } }}
            />
          </Box>

          <Box sx={styles.title}>יצירת קרן-כלל ישראל חסד ורחמים</Box>
          <Box sx={styles.title}>
            create foundation - clal israel chesed varachamim
          </Box>
          <Box sx={styles.formDetails}>
            {details.map((item) => {
              if (item.id == "city")
                return (
                  <React.Fragment key={item.id}>
                    <FormControl sx={styles.Autodetails}>
                      <Autocomplete
                        id={item.id}
                        name={item.id}
                        value={formik.values[item.id]}
                        onChange={(e, value) => {
                          formik.setFieldValue("city", value);
                        }}
                        options={cities}
                        loading={true}
                        loadingText="אין אופציות"
                        getOptionLabel={(type) => type["שם_ישוב"]}
                        isOptionEqualToValue={(option, value) =>
                          option._id === value._id
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder={item.name}
                            sx={styles.placeholder}
                            error={
                              formik.touched[item.id] &&
                              Boolean(formik.errors[item.id])
                            }
                            helperText={
                              formik.touched[item.id] && formik.errors[item.id]
                            }
                          />
                        )}
                      />
                    </FormControl>
                  </React.Fragment>
                );
              else if (item.id == "category")
                return (
                  <React.Fragment key={item.id}>
                    <FormControl sx={styles.Autodetails}>
                      <Autocomplete
                        id={item.id}
                        name={item.id}
                        value={formik.values[item.id]}
                        onChange={(e, value) => {
                          formik.setFieldValue("category", value);
                          setSelectedCategory(value);
                        }}
                        options={categories}
                        loading={true}
                        loadingText="אין אופציות"
                        getOptionLabel={(type) => type["subject"]}
                        isOptionEqualToValue={(option, value) =>
                          option._id === value._id
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder={item.name}
                            sx={styles.placeholder}
                            error={
                              formik.touched[item.id] &&
                              Boolean(formik.errors[item.id])
                            }
                            helperText={
                              formik.touched[item.id] && formik.errors[item.id]
                            }
                          />
                        )}
                      />
                    </FormControl>
                  </React.Fragment>
                );

              return (
                <TextField
                  key={item.id}
                  sx={{
                    ...styles.details,
                    mt: 6,
                    width: {
                      xs: "100%",
                      md:
                        item.id == "description" || item.id == "comments"
                          ? "100%"
                          : "23%",
                    },
                  }}
                  rows={
                    item.id == "description" || item.id == "comments" ? 5 : 1
                  }
                  multiline
                  id={item.id}
                  name={item.id}
                  variant="outlined"
                  type={item.id}
                  // placeholder={item.name}
                  label={item.name}
                  // InputLabelProps={{ shrink: true, sx: { fontSize: "1.5vw" } }}
                  fullWidth
                  value={formik.values[item.id]}
                  onChange={formik.handleChange}
                  error={
                    formik.touched[item.id] && Boolean(formik.errors[item.id])
                  }
                  helperText={formik.touched[item.id] && formik.errors[item.id]}
                />
              );
            })}
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "100%" }}
            >
              <Box component="p" sx={styles.p}>
                {"קבצים | files"}
              </Box>

              {selectedCategory && selectedCategory.fileTypes && (
                <Box component="p" sx={{ mt: 0, color: "red" }}>
                  סוגי קבצים מותרים: jpeg, jpg, png, pdf
                </Box>
              )}
            </Box>
          </Box>
          <Box sx={styles.filesBox}>
            {selectedCategory ? (
              selectedCategory.fileTypes.map((item) => (
                <Box
                  sx={{
                    display: "flex",
                    mb: 1,
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <input
                    key={item.guid}
                    type="file"
                    name="file"
                    id={item.guid}
                    onChange={handleselectedFile}
                    style={{ display: "none" }}
                  />
                  <p>{item.label}</p>
                  <label htmlFor={item.guid}>
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      sx={{ width: { xs: "100%", md: "15vw" } }}
                      endIcon={<UploadFileIcon />}
                    >
                      {formik.values[item.guid]
                        ? formik.values[item.guid].name
                        : "בחר קובץ"}
                    </Button>
                  </label>

                  {Boolean(formik.errors[item.guid]) && (
                    <FormHelperText error={true}>
                      {formik.errors[item.guid]}{" "}
                    </FormHelperText>
                  )}
                </Box>
              ))
            ) : (
              <Box component="p">בחר קטגוריה להצגת הקבצים הנדרשים</Box>
            )}
          </Box>
          <Box sx={styles.bankBox}>
            <Box component="p" sx={styles.pBank}>
              פרטי חשבון בנק להעברת הכספים
            </Box>

            <Box sx={styles.bankDetails}>
              {bankDetails.map((item) => {
                if (item.id === "bankName")
                  return (
                    <React.Fragment key={item.id}>
                      <FormControl
                        sx={{ ...styles.Autodetails, width: item.width }}
                      >
                        <Autocomplete
                          id={item.id}
                          name={item.id}
                          value={formik.values[item.id]}
                          onChange={(e, value) => {
                            formik.setFieldValue("bankName", value);
                          }}
                          options={codes}
                          loading={true}
                          loadingText="אין אופציות"
                          getOptionLabel={(type) => type.name}
                          isOptionEqualToValue={(option, value) =>
                            option.code === value.code
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder={item.name}
                              sx={styles.placeholder}
                              error={
                                formik.touched[item.id] &&
                                Boolean(formik.errors[item.id])
                              }
                              helperText={
                                formik.touched[item.id] &&
                                formik.errors[item.id]
                              }
                            />
                          )}
                        />
                      </FormControl>
                    </React.Fragment>
                  );
                return (
                  <TextField
                    key={item.id}
                    sx={{
                      ...styles.details,
                      mt: 6,
                      width: item.width,
                    }}
                    id={item.id}
                    name={item.id}
                    variant="outlined"
                    type={item.id}
                    // placeholder={item.name}
                    label={item.name}
                    // InputLabelProps={{ shrink: true, sx: { fontSize: "1.5vw" } }}
                    fullWidth
                    value={formik.values[item.id]}
                    onChange={item.id == "code" ? {} : formik.handleChange}
                    error={
                      formik.touched[item.id] && Boolean(formik.errors[item.id])
                    }
                    helperText={
                      formik.touched[item.id] && formik.errors[item.id]
                    }
                  />
                );
              })}
            </Box>
          </Box>
          <Box sx={styles.signBox}>
            <Box component="p">בחתימתי זו הנני מצהיר על אמיתות הנתונים</Box>
            <SignaturePad
              ref={sigCanvas}
              canvasProps={{ className: "sigContainer" }}
            />
            <Box sx={{ dispaly: "flex" }}>
              <IconButton disableRipple onClick={clear}>
                <DeleteIcon color="selected" />
              </IconButton>
            </Box>
          </Box>
          <Box sx={styles.submit}>
            <TextField
              sx={{
                ...styles.details,
                mb: { xs: 2, md: 0 },
              }}
              id="submitDate"
              name="submitDate"
              variant="outlined"
              type="date"
              // placeholder={item.name}
              label="תאריך"
              InputLabelProps={{ shrink: true }}
              value={formik.values.submitDate}
              onChange={formik.handleChange}
              error={
                formik.touched.submitDate && Boolean(formik.errors.submitDate)
              }
              helperText={formik.touched.submitDate && formik.errors.submitDate}
            />
            <Button
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
              form="applicationForm"
              sx={styles.sButton}
              disabled={isSubmitting}
            >
              {isSubmitting ? "נשלח, נא להמתין" : "שלח"}
            </Button>
          </Box>
        </Box>
      </Box>
      {error && (
        <Snackbar
          open={openSnack}
          autoHideDuration={6000}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          onClose={handleSnackClose}
        >
          <Alert
            onClose={handleSnackClose}
            variant="filled"
            severity="error"
            sx={{ width: "100%" }}
          >
            אירעה שגיאה.נסה שוב
          </Alert>
        </Snackbar>
      )}

      {submitSuccess && (
        <Snackbar
          open={openSnack}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          onClose={handleSnackClose}
        >
          <Alert
            onClose={handleSnackClose}
            variant="filled"
            severity="success"
            sx={{ width: "100%" }}
          >
            קרן נוצרה בהצלחה!
          </Alert>
        </Snackbar>
      )}
    </>
  );
}
