import {
    Box, Button, TextField, CircularProgress,
    Snackbar, Alert, Select, MenuItem, FormControl,
    FormHelperText
} from "@mui/material"
import styles from "./style"
import theme from "../../themes/default"
import { useFormik } from 'formik';
import { useState, useEffect } from "react";



export default function Register({ loading, error, submitSuccess, onSubmit, onMount, changeTab }) {

    const [openSnack, setOpenSnack] = useState(true);




    const roles = [
        {
            name: "מנהל",
            id: "admin"
        },
        {
            name: "עובד משרד",
            id: "officer"
        },
        {
            name: "מגייס כספים",
            id: "recruiting"
        },
    ]
    const details = [
        {
            name: "שם",
            id: "name"
        },
        {
            name: 'כתובת מייל',
            id: "email"
        },
        {
            name: "טלפון",
            id: "phone"
        },
        {
            name: "תפקיד",
            id: "role"
        }
    ]



    useEffect(() => {
        setOpenSnack(true)
    }, [error, submitSuccess])

    useEffect(() => {
        onMount()
        changeTab(2)
    }, [])


    const validateInputs = (values) => {
        const errors = {};
        if (!values.email) {
            errors.email = 'יש להזין כתובת מייל';
        } else if (
            !/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(values.email)

        ) {
            errors.email = 'כתובת מייל אינה תקינה';
        }
        if (!values.name) {
            errors.name = 'יש להזין שם';
        }
        if (!values.phone) {
            errors.phone = "יש להזין מס' טלפון";
        } else if (!/^[0-9]*$/.test(values.phone)) {
            errors.phone = "מספרים בלבד";
        }
        else if (values.phone.length < 10) {
            errors.phone = "מס' לא תקין";
        }
        if (values.role == 0 || !values.role) {
            errors.role = "יש לבחור תפקיד";
        }
        return errors;
    }


    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            phone: '',
            role: 0
        },
        validate: validateInputs,
        onSubmit: (values) => {
            onSubmit(values)
        },
    });

    const handleSnackClose = () => {
        setOpenSnack(false)
    }



    return (
        <>
            <Box sx={styles.wrapper}>
                <Box sx={styles.title}
                >
                    <Box sx={styles.paragraph}>
                        כלל ישראל חסד ורחמים</Box>
                </Box>
                <Box
                    component="form"
                    onSubmit={formik.handleSubmit}
                    id="loginForm"
                    sx={styles.loginForm}>
                    <Box sx={styles.connect}>
                        הרשמה</Box>
                    <Box
                        sx={styles.form}>
                        {details.map((item) => {
                            if (item.id == "role") {
                                return (
                                    <FormControl
                                        key={item.id}
                                        sx={{ pt: 2, width: "47%", }}>
                                        <Select
                                            id={item.id}
                                            name={item.id}
                                            value={formik.values[item.id]}
                                            sx={{ height: "8vh", backgroundColor: "#ffffff" }}
                                            onChange={formik.handleChange}
                                        >

                                            <MenuItem value={0} sx={{ fontSize: "1.2vw" }} disabled>בחירה מהרשימה</MenuItem>
                                            <MenuItem value="admin" sx={{ fontSize: "1.2vw" }}>מנהל</MenuItem>
                                            <MenuItem value="officer" sx={{ fontSize: "1.2vw" }}>עובד משרד</MenuItem>
                                            <MenuItem value="recruiting" sx={{ fontSize: "1.2vw" }}>מגייס</MenuItem>
                                        </Select>
                                        {formik.touched.role &&
                                            Boolean(formik.errors.role) &&
                                            <FormHelperText error={true}>יש לבחור תפקיד</FormHelperText>
                                        }
                                    </FormControl>
                                )
                            }
                            return (
                                <TextField
                                    key={item.id}
                                    sx={{ ...styles.details, pt: 2 }}
                                    id={item.id}
                                    name={item.id}
                                    variant="outlined"
                                    type={item.id == "email" ? "email" : item.id == "phone" ? "phone" : ""}
                                    placeholder={item.name}
                                    fullWidth
                                    value={formik.values[item.id]}
                                    onChange={formik.handleChange}
                                    error={formik.touched[item.id] && Boolean(formik.errors[item.id])}
                                    helperText={formik.touched[item.id] && formik.errors[item.id]}
                                />
                            )
                        })}
                        <Box sx={{ width: "100%", m: "auto", pt: 2 }}>

                            <Button
                                fullWidth
                                variant="contained"
                                form="loginForm"
                                type="submit"
                                sx={styles.submit}
                            >
                                {loading ? <CircularProgress size={26} sx={{ color: theme.palette.text.primary }} /> : "הירשם"}
                            </Button>

                        </Box>
                    </Box>
                    <Box>

                    </Box>

                </Box>

            </Box >
            {
                error &&
                (<Snackbar
                    open={openSnack}
                    autoHideDuration={6000}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    onClose={handleSnackClose}>
                    <Alert
                        onClose={handleSnackClose}
                        variant="filled"
                        severity="error"
                        sx={{ width: '100%' }}>
                        אירעה שגיאה.בדוק את הפרטים ונסה שוב
                    </Alert>

                </Snackbar>)}

            {submitSuccess && <Snackbar
                open={openSnack}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }}
                onClose={handleSnackClose}>
                <Alert
                    onClose={handleSnackClose}
                    variant="filled"
                    severity="success"
                    sx={{ width: '100%' }}>
                    משתמש נרשם בהצלחה
                </Alert>
            </Snackbar>}

        </>
    )
}