import theme from "../../themes/default";

const style = {
    mainWrap: {
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "flex-start",
        p: 4
    },
    main: {
        width: "100%",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        p: 5,
        boxSizing: "border-box",
        overflowY: "auto"

    },

    header: {
        background: "#D4D4D4",
        borderRadius: "8px 8px 0 0",
        // height: "9%",
        display: "flex",
        justifyContent: "flex-start",
        p: 2,
        boxSizing: "border-box"
    },
    lists: {
        display: "flex",
        width: "80%",
        justifyContent: "space-between"
    },
    list: {
        width: "35%",
        display: "flex",
        flexDirection: "column"
    },
    newButton: {
        borderRadius: "0 0 0 0",
        width: "40%",
        fontWeight: "700",
        p: 1
    },
    prayer: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        width: "100%",
        // height: "10%",
        background: "#F7F7F7",
        mb: 1,
        p: 0.5,
        boxSizing: "border-box"
    },
    events: {
        display: "flex",
        justifyContent: "flex-start",
        width: "100%",
        background: "#F7F7F7",
        mb: 1,
        p: 0.5,
        boxSizing: "border-box"
    },
    event: {
        display: "flex",
        p: 1,
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%"
    },
    wrap: {
        display: "flex",
        p: 1,
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%"
    },
    serviceInfo: {
        display: "flex",
        px: 1,
        justifyContent: "space-between",
        width: "100%"
    },
    serviceName: {
        fontWeight: "bold",
        fontSize: "0.8rem",
        mt: 0,
        mb: 0.5
    },
    servicesWrap: {
        width: "40%",
        display: "flex",
        flexDirection: "column"
    },
    headerText: {
        fontWeight: "bold",
        my: "auto",
        width: "33%",
        textAlign: "center"
    },
    name: {
        fontWeight: "bold",
        fontSize: "0.8rem",
        width: "66%"
    },
    title: {
        fontWeight: "700",
        opacity: 1,
        fontSize: "1.2vw"
    },
    content: {
        color: theme.palette.primary.main,
        opacity: 1
    },
    actionBox: {
        display: "flex",
        justifyContent: "center"
    }

}





export default style;