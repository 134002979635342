import { createSelector } from 'reselect'

export const sliceSelector = (state) => state.funds;
export const filter = createSelector(sliceSelector, slice => slice.filter);
export const isLoading = createSelector(sliceSelector, slice => slice.loading);
export const funds = createSelector(sliceSelector, slice => slice.funds);
export const error = createSelector(sliceSelector, slice => slice.error)
export const submitSuccess = createSelector(sliceSelector, slice => slice.submitSuccess)
export const fund = createSelector(sliceSelector, slice => slice.fund);
export const funsExpenses = createSelector(sliceSelector, slice => slice.funsExpenses);
export const isSubmitting = createSelector(sliceSelector, slice => slice.isSubmitting);
