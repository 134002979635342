import {
    put,
    call,
    takeLatest,
} from "redux-saga/effects";
import {
    Actions as fundsActions,
} from "./actions";
import { ActionTypes as PagesActions } from "../../pages/actions";
import axios from "../../services/axios";
// import config from 'config';
import _ from "lodash";

function* fetchFunds(action) {
    let { payload, type } = action;
    try {
        if (payload.page === 0) {
            yield put(fundsActions.FETCH_FUNDS_REQUEST())
        }
        const { data } = yield call(axios.get,
            `funds?page=${payload.page}&take=10${payload.filter}`);
        yield put(fundsActions.FETCH_FUNDS_SUCCESS(data));
    } catch (err) {
        console.error(err)
        yield put(fundsActions.FETCH_FUNDS_FAILURE(err));
    }
}

function* getSingleFund(action) {
    try {
        const { payload } = action;
        yield put(fundsActions.SINGLE_FUND_REQUEST());
        const { data } = yield call(axios.get, `/funds/${payload}`);
        yield put(fundsActions.SINGLE_FUND_SUCCESS(data));
    } catch (err) {
        console.error(err)
        yield put(fundsActions.SINGLE_FUND_FAILURE(err));
    }
}

// function* resetFundData(action) {
//     try {
//         yield put(fundsActions.SINGLE_FUND_SUCCESS(null));
//         console.log('did reset');
//         //yield put(fundsActions.RESET_FUND_DATA());
//     } catch (e) {
//         console.log(e);
//     }
// }

function* editFund(action) {
    try {
        const { payload } = action;
        yield put(fundsActions.EDIT_FUND_REQUEST());
        const id = payload.id;
        const details = payload.details;
        var bodyFormData = new FormData();
        for (var key in details) {
            if (details.hasOwnProperty(key) && key === "bankAccount") {
                bodyFormData.append(key, JSON.stringify(details[key]));
            }
            else if (details.hasOwnProperty(key) && key === "dependent") {
                bodyFormData.append(key, JSON.stringify(details[key]));
            }
            else if (details.hasOwnProperty(key) && key === "files") {
                if (details.files) {
                    const files = details.files
                    for (var key in files) {
                        bodyFormData.append(key, files[key].file, files[key].name)
                    }
                }

            }
            else if (details.hasOwnProperty(key) && (key === "fileBookKeepingAuthorization" || key === "fileProperManagement"
                || key === "fileRequest")) {
                if (typeof details[key] === 'object' && details[key] !== null && !Array.isArray(details[key])) {
                    const files = details[key]
                    bodyFormData.append(key, files.file, files.name)
                }
                else {
                    bodyFormData.append(key, details[key])

                }

            }
            else if (details.hasOwnProperty(key)) {
                bodyFormData.append(key, details[key]);
            }
        }
        const { data } = yield call(axios.put, `/funds/update/${id}`, bodyFormData, { headers: { 'Content-Type': 'multipart/form-data' } });
        yield put(fundsActions.EDIT_FUND_SUCCESS());
    } catch (err) {
        console.error(err)
        yield put(fundsActions.EDIT_FUND_FAILURE(err));
    }
}

function* createInstituteApplication(action) {
    try {
        const payload = action.payload;
        var bodyFormData = new FormData();
        for (var key in payload) {
            if (payload.hasOwnProperty(key) && key === "bankAccount") {
                bodyFormData.append(key, JSON.stringify(payload[key]));
            }
            else if (payload.hasOwnProperty(key) && key === "dependent") {
                bodyFormData.append(key, JSON.stringify(payload[key]));
            }
            else if (payload.hasOwnProperty(key) && key === "files") {
                if (payload.files) {
                    const files = payload.files
                    for (var key in files) {
                        bodyFormData.append(key, files[key].file, files[key].name)
                    }
                }

            }

            else if (payload.hasOwnProperty(key) && (key === "fileBookKeepingAuthorization" || key === "fileProperManagement"
                || key === "fileRequest")) {
                if (payload[key]) {
                    const files = payload[key]
                    // for (var key in files) {
                    bodyFormData.append(key, files.file, files.name)
                    // }
                }

            }
            else if (payload.hasOwnProperty(key)) {
                bodyFormData.append(key, payload[key]);
            }
        }
        bodyFormData.append('fundType', 'mosad');
        yield put(fundsActions.NEW_APPLICATION_REQUEST());
        // yield call(axios.get,
        //     `inquiries/report/?${payload}`, {
        //     headers: {
        //         responseType: 'blob',
        //     }
        // })
        const { data } = yield call(axios.post, `funds/create`, bodyFormData, { headers: { 'Content-Type': 'multipart/form-data' } });
        yield put(fundsActions.NEW_APPLICATION_SUCCESS());

    } catch (err) {
        console.error(err);
        yield put(fundsActions.NEW_APPLICATION_FAILURE());
    }
}



const sagas = [
    takeLatest(PagesActions.FUNDS_PAGE_MOUNTED, fetchFunds),
    // takeLatest(PagesActions.SINGLE_DEPENDENT_PAGE_MOUNTED, getSingleDependent),
    // takeLatest(PagesActions.GET_DEPENDENT_INQUIRY, getDependentInquiries),
    takeLatest(PagesActions.SINGLE_FUND_PAGE_MOUNTED, getSingleFund),
    takeLatest(PagesActions.GET_SINGLE_FUND, getSingleFund),
    takeLatest(PagesActions.EDIT_FUND_FORM_SUBMITTED, editFund),
    takeLatest(PagesActions.INSTITUTE_APPLICATION_FORM_SUBMITTED, createInstituteApplication),
    // takeLatest(PagesActions.RESET_FUND_DATA, resetFundData),

];
export default sagas;
