import { connect } from "../../utils/reduxUtils"
import Component from "./InstituteFundForm"
import { Actions } from "../actions"
import * as FundSelectors from "../../modules/funds/selectors"


const mapDispatchToProps = {
    onMount: Actions.SINGLE_FUND_PAGE_MOUNTED,
    onSubmit: Actions.INSTITUTE_APPLICATION_FORM_SUBMITTED,
    changeTab: Actions.ACTIVE_TAB_CHANGED,



};

const mapStateToProps = state => {
    return {
        isSubmitting: FundSelectors.isSubmitting(state),
        error: FundSelectors.error(state),
        submitSuccess: FundSelectors.submitSuccess(state),
        fund:FundSelectors.fund(state)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);



