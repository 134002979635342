import { connect } from "../../utils/reduxUtils"
import Component from "./CreateExpense"
import { Actions } from "../../pages/actions"
import * as ExpensesSelectors from "../../modules/expenses/selectors"


const mapDispatchToProps = {
    onSubmit: Actions.CREATE_EXPENSE_SUBMITTED,
    onUpdate:Actions.UPDATE_EXPENSE_SUBMITTED //TODO: change it to right rout
};

const mapStateToProps = state => {
    return {
        loading: ExpensesSelectors.isLoading(state),
        error: ExpensesSelectors.error(state),
        submitSuccess: ExpensesSelectors.submitSuccess(state),
        isSubmitting:ExpensesSelectors.isSubmitting(state)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);



