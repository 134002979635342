
import Drawer from '@mui/material/Drawer';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import { Box, Avatar, Menu, Typography } from '@mui/material';
import theme from '../../themes/default';
import { useEffect, useState } from 'react';
import styles from './style'
import { Link, Navigate } from 'react-router-dom';
import * as AuthSelectors from "../../modules/auth/selectors"
import { useSelector } from 'react-redux';
import logo from "../../images/logo.png"





export default function SideBar({ onLogout, name, activeTab }) {

    const role = sessionStorage.getItem('userRole')


    const structure = [
        { id: 1, label: "דשבורד", link: "/dashboard" },
        { id: 2, label: "משתמשים", link: "/users", },
        { id: 3, label: "קרנות", link: "/funds" },
        { id: 4, label: "הוצאות", link: '/expenses/draft' },
        { id: 5, label: "הוצאות שהושלמו", link: '/expenses/finished' },
        { id: 6, label: 'ממתינים למס"ב', link: '/expenses/masav' },
        { id: 9, label: 'דו"חות', link: "/reports" },
        { id: 10, label: 'ניהול קטגוריות', link: "/categories" },
    ];

    const handleClick = (item) => {
        localStorage.setItem("tab", item.id)
        setSelected(localStorage.getItem("tab"))
        localStorage.setItem("tab", item.id)


    }

    useEffect(() => {
        if (!localStorage.getItem("tab")) {
            localStorage.setItem("tab", 1)
            setSelected(localStorage.getItem("tab"))
        }
        else setSelected(localStorage.getItem("tab"))
    }, [])


    useEffect(() => {
        localStorage.setItem("tab", activeTab)
        setSelected(activeTab)
    }, [activeTab])

    const [selected, setSelected] = useState()
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleAvatar = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleMenuClick = () => {
        setAnchorEl(null);
        onLogout();
    }


    return (
        <>

            <Drawer
                open
                anchor="left"
                variant="persistent"
                PaperProps={{
                    sx: {
                        backgroundColor: "#F7F7F7",
                        display: "flex",
                        flexDirection: "column",
                        width: "14%"
                    }
                }}

            >
                <Box sx={styles.logoWrapper}>
                    <img
                        src={logo}
                        alt="logo"
                        style={styles.logo}
                    />
                </Box>
                <MenuList>
                    {structure.map((item) => {
                        if (item.id === 2) {
                            return (role === "admin" &&
                                <MenuItem
                                    key={item.id}
                                    component={Link}
                                    to={item.link}
                                    sx={{
                                        bgcolor: selected == item.id ? theme.palette.primary.main : null,
                                        color: "#000000",
                                        height: "6vh",
                                        '&:focus': {
                                            backgroundColor: selected == item.id ? theme.palette.primary.main : null,
                                        }

                                    }}
                                    disableRipple
                                    onClick={() => { handleClick(item) }}
                                >
                                    <ListItemText
                                        disableTypography
                                        primary={
                                            <Typography
                                                type="body2"
                                                sx={{ fontSize: "1.1vw", textAlign: "center", fontWeight: "500" }}
                                            >{item.label}
                                            </Typography>
                                        }
                                    >
                                    </ListItemText>
                                </MenuItem>)
                        }
                        if (item.id === 10) {
                            return ((role === "admin" || role === "officer") &&
                                <MenuItem
                                    key={item.id}
                                    component={Link}
                                    to={item.link}
                                    sx={{
                                        bgcolor: selected == item.id ? theme.palette.primary.main : null,
                                        color: "#000000",
                                        height: "6vh",
                                        '&:focus': {
                                            backgroundColor: selected == item.id ? theme.palette.primary.main : null,
                                        }

                                    }}
                                    disableRipple
                                    onClick={() => { handleClick(item) }}
                                >
                                    <ListItemText
                                        disableTypography
                                        primary={
                                            <Typography
                                                type="body2"
                                                sx={{ fontSize: "1.1vw", textAlign: "center", fontWeight: "500" }}
                                            >{item.label}
                                            </Typography>
                                        }
                                    >
                                    </ListItemText>
                                </MenuItem>)
                        }
                        else {
                            return (
                                <MenuItem
                                    key={item.id}
                                    component={Link}
                                    to={item.link}
                                    sx={{
                                        bgcolor: selected == item.id ? theme.palette.primary.main : null,
                                        color: "#000000",
                                        height: "6vh",
                                        '&:focus': {
                                            backgroundColor: selected == item.id ? theme.palette.primary.main : null,
                                        }

                                    }}
                                    disableRipple
                                    onClick={() => { handleClick(item) }}
                                >
                                    <ListItemText
                                        disableTypography
                                        primary={
                                            <Typography
                                                type="body2"
                                                sx={{ fontSize: "1.1vw", textAlign: "center", fontWeight: "500" }}
                                            >{item.label}
                                            </Typography>
                                        }
                                    >
                                    </ListItemText>
                                </MenuItem>
                            )
                        }


                    })}

                </MenuList>
                <Box
                    width="95%"
                    sx={styles.sideBar}
                >
                    <Box
                        sx={styles.logged}>
                        <IconButton
                            id="basic-button"
                            variant="outlined"
                            color="primary"
                            disableFocusRipple
                            disableRipple
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleAvatar}
                        >
                            <Avatar
                                sx={styles.avatar}
                            />
                        </IconButton>
                        <Box
                            component="p"
                            sx={styles.userName}
                        >מחובר: {name}
                        </Box>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem onClick={handleMenuClick}>התנתק</MenuItem>
                        </Menu>
                    </Box>

                </Box>
            </Drawer>



        </>
    )

}