import React from "react";
import {
    Route,
    Routes,
} from "react-router-dom";
import { Box } from '@mui/material';
import style from "./style";
// component
import Sidebar from "../../components/sideBar";
// pages
import PrivateFundForm from "../../pages/privateFundForm";
import Login from "../../pages/login";
import Error from "../../pages/error";
import Dashboard from "../../pages/dashboard";
import Users from "../../pages/users";
import Register from "../../pages/register";
import Funds from '../../pages/funds';
import ForgotPassword from "../../pages/forgotPass";
import ResetPassword from "../../pages/resetPassword";
import Reports from "../../pages/reports"
import SingleUser from "../../pages/singleUser";
import CompletedExpenses from "../../pages/completedExpenses/CompletedExpenses";
import PendingExpenses from "../../pages/pendingExpenses/PendingExpenses";
import PendingMasavExpenses from "../../pages/pendingMasavExpenses";
import Categories from "../../pages/categories";
import SingleCategory from "../../pages/singleCategory";
import SingleInstituteFund from "../../pages/singleInstituteFund"
import SinglePrivateFund from "../../pages/singlePrivateFund";




function Layout() {

    return (
        <Box sx={style.root}>
            <>

                <Sidebar />
                <Box
                    sx={{
                        ...style.content
                    }}
                >


                    <Routes>
                        <Route path="/" element={<PrivateFundForm />} />
                        <Route path="/dashboard" element={<Dashboard />} />
                        <Route path="/register" element={<Register />} />
                        <Route path="/funds" element={<Funds />} />
                        <Route path="/funds/:id" element={<SinglePrivateFund />} />
                        <Route path="/funds/mosad/:id" element={<SingleInstituteFund />} />
                        <Route path="/users" element={<Users />} />
                        <Route path="/users/:id" element={<SingleUser />} />
                        <Route path="/expenses/draft" element={<PendingExpenses />} />
                        <Route path="/expenses/masav" element={<PendingMasavExpenses />} />
                        <Route path="/expenses/finished" element={<CompletedExpenses />} />
                        <Route path="/forgot-password" element={<ForgotPassword />} />
                        <Route path="/reset-password/:guid" element={<ResetPassword />} />
                        <Route path="/reports" element={<Reports />} />
                        <Route path="/categories" element={<Categories />} />
                        <Route path="/categories/:id" element={<SingleCategory />} />
                        <Route path="login" element={<Login />} />
                        <Route path="*" element={<Error />} />

                    </Routes>

                </Box>
            </>
        </Box>
    );
}

export default Layout