import { connect } from "../../utils/reduxUtils"
import Component from "./PrivateFundForm"
import { Actions } from "../actions"
import * as FundSelectors from "../../modules/funds/selectors"


const mapDispatchToProps = {
    onMount: Actions.APPLICATION_FORM_MOUNTED,
    onSubmit: Actions.APPLICATION_FORM_SUBMITTED,
    changeTab: Actions.ACTIVE_TAB_CHANGED,


};

const mapStateToProps = state => {
    return {
        isSubmitting: FundSelectors.isSubmitting(state),
        error: FundSelectors.error(state),
        submitSuccess: FundSelectors.submitSuccess(state),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);



