import {
  Container,
  Typography,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import SingleFundHeader from "../../components/singleFundHeader/SingleFundHeader";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DataTable from "../../components/DataTable";
import CreateExpense from "../../components/createExpense";
import EditPrivateFormDialog from "../../components/editPrivateFormDialog";

export default function SinglePrivateFund({
  changeTab,
  onMount,
  getExpenses,
  data,
  loading,
  submitSuccess,
  fund,
  submitFundSuccess,
  submitFundError,
  getFund,
  onDelete,
  setSubmitSuccess,
}) {
  const [selected, setSelected] = useState();
  const [sortedTable, setsortedTable] = useState(data);
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [openEditForm, setOpenEditForm] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [amount, setAmount] = useState("");
  const [date, setDate] = useState("");
  const [update, setupdate] = useState(false);
  const [expenseId, setExpenseId] = useState("");

  const onChangePagination = (newPage) => {
    getExpenses({ page: newPage, id: id });
    setPage(newPage);
  };

  const handleSnackClose = () => {
    setOpenSnack(false);
  };

  useEffect(() => {
    setOpenSnack(true);
  }, [submitFundError, submitFundSuccess]);

  useEffect(() => {
    setsortedTable(data);
  }, [data]);

  const { id } = useParams();

  useEffect(() => {
    changeTab(3);
  }, []);
  const handleDeleteClick = (id) => {
    onDelete(id);
  };

  const handleAction = (row) => {
    console.log("in handel action");
    setAmount(row.amount);
    setupdate(true);
    console.log(row.updatedAt);
    console.log(row.createdAt);
    setDate(row.createdAt);
    setOpen(true);
    setExpenseId(row.id);
  };

  useEffect(() => {
    if (submitSuccess) {
      getExpenses({ page: 0, id: id });
      setSubmitSuccess();
    }
  }, [submitSuccess]);

  useEffect(() => {
    if (id) {
      onMount(id);
      getExpenses({ page: 0, id: id });
    }
  }, [id]);

  useEffect(() => {
    if (submitFundSuccess) {
      setOpenEditForm(false);
      getFund(id);
    }
  }, [submitFundSuccess]);

  const showData = (data) => {
    let showtable = [];
    let date;
    let ddate;

    if (data.length > 0) {
      data.map((item, index) => {
        date = new Date(item.updatedAt);
        ddate =
          date.getDate() +
          "." +
          (date.getMonth() + 1) +
          "." +
          date.getFullYear();
        showtable.push({ ...item });
        showtable[index] = {
          ...showtable[index],
          fundName:
            item.fund?.fundType === "mosad"
              ? item.fund?.mosadName
              : item.fund?.firstName + " " + item.fund?.lastName,
          categoryName: item.category?.subject,
          // cityName: item.dependent.city,
          updatedAt: ddate,
          statusLabel:
            item.status === "draft"
              ? "טיוטה"
              : item.status === "masav"
              ? 'ממתין למס"ב'
              : "הושלם",
        };
      });
    }
    return showtable;
  };

  let headers = [
    {
      name: "שם הקרן",
      width: "15%",
      table: "fundName",
    },
    {
      name: "סכום",
      width: "10%",
      table: "amount",
    },
    {
      name: "תאריך",
      width: "10%",
      table: "updatedAt",
    },
    {
      name: "סטטוס הוצאה",
      width: "10%",
      table: "statusLabel",
    },
    {
      name: "עדכן",
      width: "10%",
      table: "Action",
    },
    {
      name: "מחק",
      width: "10%",
      table: "deletLabel",
    },
  ];
  return (
    <>
      <SingleFundHeader
        selected={selected}
        setSelected={setSelected}
        setOpen={setOpen}
        setOpenEditForm={setOpenEditForm}
        setDate={setDate}
        setAmount={setAmount}
        setupdate={setupdate}
      />
      <Container
        maxWidth="xl"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography sx={{ pt: 5, fontSize: "1.3rem", fontWeight: "bold" }}>
          רשימת הוצאות לקרן
        </Typography>

        {loading ? (
          <CircularProgress
            color="primary"
            sx={{ position: "absolute", top: "50%", left: "50%" }}
          />
        ) : (
          <DataTable
            fdata={sortedTable}
            showData={showData}
            headers={headers}
            onChange={onChangePagination}
            page={page}
            disableClick={true}
            setOpen={setOpen}
            setAmount={setAmount}
            setDate={setDate}
            setExpenseId={setExpenseId}
            handleDelete={handleDeleteClick}
            handleActionClick={handleAction}
            buttonLable={"עריכה"}
          />
        )}
      </Container>
      <CreateExpense
        open={open}
        setOpen={setOpen}
        id={id}
        amount={amount}
        date={date}
        update={update}
        expenseId={expenseId}
      />
      <EditPrivateFormDialog
        openEditFormDialog={openEditForm}
        setOpenEditFormDialog={setOpenEditForm}
        fund={fund}
        id={id}
      />
      {submitFundSuccess && (
        <Snackbar
          open={openSnack}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          onClose={handleSnackClose}
        >
          <Alert
            onClose={handleSnackClose}
            variant="filled"
            severity="success"
            sx={{ width: "100%" }}
          >
            קרן עודכנה בהצלחה!
          </Alert>
        </Snackbar>
      )}
    </>
  );
}
