import {
    Box, Stack, Switch, TextField,
    CircularProgress, Table, TableBody, TableRow, TableCell,
    TableHead, TableFooter, IconButton, Button,
    Snackbar, Alert, Checkbox, FormControlLabel
} from "@mui/material"
import AddIcon from '@mui/icons-material/Add';
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useFormik } from 'formik'
import styles from './style'
import DeleteIcon from '@mui/icons-material/Delete';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';


export default function SingleCategory({
    changeTab, onMount, category,
    loading, onSubmit, error, submitSuccess,
    isSubmitting, getCategory, createCategory
}) {

    const { id } = useParams()
    const [files, setFiles] = useState([])
    const [openSnack, setOpenSnack] = useState(false)

    const navigate = useNavigate();
    useEffect(() => {
        changeTab(10)
        onMount();
        if (id != "new")
            getCategory(id)
    }, [id])

    useEffect(() => {
        setOpenSnack(true)
    }, [error, submitSuccess])

    useEffect(() => {
        if (category && category.fileTypes) {
            setFiles(category.fileTypes)
        }
        else setFiles([])
    }, [category])

    const handleChange = (event) => {
        formik.setFieldValue("isActive", event.target.checked);
    };

    const changeFileName = (e, index) => {
        var result = [...files]; //<- copy roomRent into result
        result[index].label = e.target.value;
        setFiles(result); //<- update roomRent with value edited
    };

    const changeFileStatus = (e, index) => {
        var result = [...files]; //<- copy roomRent into result
        result[index].isActive = e.target.checked;
        setFiles(result); //<- update roomRent with value edited //<- update roomRent with value edited
    };

    const addFile = () => {
        var result = [...files]
        result.push({ label: "", isActive: true, id: "" })
        setFiles(result)
    }

    const removeFile = (index) => {
        var result = [...files]
        const removed = result.splice(index, 1);
        setFiles(result)
    }

    const handleSnackClose = () => {
        setOpenSnack(false)
    }

    const validateInputs = (values) => {
        const errors = {};
        if (!values.categoryName) {
            errors.categoryName = 'יש להזין שם קטגוריה';
        }
        if (!values.categoryNameEn) {
            errors.categoryNameEn = 'יש להזין שם קטגוריה באנגלית';
        }
        if (files && files.length > 0) {
            files.forEach(function (item, index) {
                if (!item.label)
                    errors[index] = true
            })
        }
        return errors;
    }

    const formik = useFormik({
        initialValues: {
            categoryName: category ? category.subject : '',
            categoryNameEn: category ? category.subjectEn : '',
            isActive: category ? category.isActive : true,
            showMosadForm: category ? category.showMosadForm : false,
            showPrivateForm: category ? category.showPrivateForm : false
        },
        enableReinitialize: true,
        validate: validateInputs,
        onSubmit: async(values) => {
            let array = []
            if (files.length > 0)
                array = files.map(row => _.pick(row, 'label', 'isActive', row.id ? 'id' : ''))
            let details = {
                subject: values.categoryName,
                subjectEn: values.categoryNameEn,
                isActive: values.isActive,
                showMosadForm: values.showMosadForm,
                showPrivateForm: values.showPrivateForm,
                fileTypes: array
            }
            if (id !== "new") {
                try{
                    const editActionPromise =  onSubmit({ id: id, details: details });
                    await editActionPromise;
                    navigate("/categories")

                }
                catch(error){
                    console.error('Error while creating:', error);
                }
            }
            else {
                try{
                    const createActionPromise = await createCategory(details);
                    console.log(createActionPromise); 
                    navigate("/categories")

                }
                catch(error){
                    console.error('Error while creating:', error);
                }

            }
        },
    });


    return (
        <>

            {
                loading ?
                    <CircularProgress sx={{ position: "absolute", top: "50%", left: "50%" }} />
                    : (<Box
                        component="form"
                        onSubmit={formik.handleSubmit}

                        id="categoryForm"
                        sx={styles.main} >
                        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "40%" }}>
                            <Box
                                component="p"
                                sx={{ fontSize: "1.5vw", fontWeight: "700" }}
                            >{`ניהול קטגוריה ${category ? category.subject : ''}`}
                            </Box>
                            <Button
                                variant="contained"
                                form="categoryForm"
                                type="submit"
                                sx={{ fontWeight: "600" }}
                            >{isSubmitting ? <CircularProgress sx={{ color: "#000000" }} size={18} /> : "שמור"}</Button>
                        </Box>
                        <Box
                            sx={styles.category} >
                            <TextField
                                sx={{
                                    ...styles.details,
                                    mt: 5,
                                }}
                                id="categoryName"
                                name="categoryName"
                                variant="outlined"
                                label="שם הקטגוריה"
                                InputLabelProps={{ shrink: true }}
                                value={formik.values.categoryName}
                                onChange={formik.handleChange}
                                error={formik.touched.categoryName && Boolean(formik.errors.categoryName)}
                                helperText={formik.touched.categoryName && formik.errors.categoryName}
                            />
                            <TextField
                                sx={{
                                    ...styles.details,
                                    mt: 5,
                                }}
                                id="categoryNameEn"
                                name="categoryNameEn"
                                variant="outlined"
                                label="שם הקטגוריה באנגלית"
                                InputLabelProps={{ shrink: true }}
                                value={formik.values.categoryNameEn}
                                onChange={formik.handleChange}
                                error={formik.touched.categoryNameEn && Boolean(formik.errors.categoryNameEn)}
                                helperText={formik.touched.categoryNameEn && formik.errors.categoryNameEn}
                            />
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Switch
                                    checked={formik.values.isActive}
                                    onChange={handleChange}
                                    color="primary"
                                    sx={{ mt: 5 }}
                                />
                            </Stack>

                        </Box>
                        <Box sx={{ display: "flex", width: "40%", mx: "auto", mt: 2, flexDirection: "column" }}>
                            <Box>
                                הצגת קטגוריה בקרן:
                            </Box>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id="showPrivateForm"
                                        name="showPrivateForm"
                                        checked={formik.values.showPrivateForm}
                                        onChange={formik.handleChange}
                                        color="primary"
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />}
                                label="פרטית"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id="showMosadForm"
                                        name="showMosadForm"
                                        checked={formik.values.showMosadForm}
                                        onChange={formik.handleChange}
                                        color="primary"
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />}
                                label="מוסדית"
                            />
                        </Box>
                        <Table sx={{ width: "40%", mt: "2%", mx: "auto" }}>
                            <TableHead >
                                <TableRow
                                    sx={{ backgroundColor: "#D4D4D4", borderBottom: "none", }}
                                >

                                    <TableCell

                                        sx={{
                                            borderRadius: "8px 0px 0px 0px ",
                                            ...styles.tabelHeader
                                        }}>
                                        סוגי קבצים
                                    </TableCell>
                                    <TableCell

                                        sx={{
                                            borderRadius: "0px 8px 0px 0px  ",
                                            ...styles.tabelHeader
                                        }}>
                                        <IconButton
                                            disableRipple
                                            onClick={addFile}
                                            sx={{ px: 0, width: "33%" }}>
                                            <AddIcon color="selected" />
                                        </IconButton>
                                    </TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {files && files.map((row, index) => (
                                    <TableRow
                                        key={row.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 }, }}
                                    >
                                        < TableCell
                                            align="left"
                                            sx={styles.tabelCell}>
                                            <TextField
                                                sx={{
                                                    ...styles.details,
                                                }}
                                                id={`${index}`}
                                                name={`${index}`}
                                                variant="outlined"
                                                placeholder="שם הקובץ"
                                                InputLabelProps={{ shrink: true }}
                                                value={row.label}
                                                onChange={(e) => changeFileName(e, index)}
                                                error={Boolean(formik.errors[`${index}`]) && !row.label}
                                                helperText={formik.errors[`${index}`] && !row.label && "נא להזין שם קובץ"}
                                            />
                                        </TableCell>
                                        < TableCell
                                            align="left"
                                            sx={styles.tabelCell}>
                                            <Switch
                                                checked={row.isActive}
                                                onChange={(e) => changeFileStatus(e, index)}
                                                color="primary"
                                            />
                                            {!row.id && <IconButton
                                                disableRipple
                                                onClick={() => { removeFile(index) }}
                                                sx={{ px: 0, width: "33%" }}>
                                                <DeleteIcon color="selected" />
                                            </IconButton>}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                            <TableFooter sx={{ height: 'auto !important', p: 1, }}>
                                <TableRow>
                                </TableRow>
                            </TableFooter>

                        </Table>

                    </Box >)
            }
            {
                error &&
                (<Snackbar
                    open={openSnack}
                    autoHideDuration={6000}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    onClose={handleSnackClose}>
                    <Alert
                        onClose={handleSnackClose}
                        variant="filled"
                        severity="error"
                        sx={{ width: '100%' }}>
                        אירעה שגיאה.נסה שוב
                    </Alert>

                </Snackbar>)}

            {submitSuccess && <Snackbar
                open={openSnack}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }}
                onClose={handleSnackClose}>
                <Alert
                    onClose={handleSnackClose}
                    variant="filled"
                    severity="success"
                    sx={{ width: '100%' }}>
                    נשמר בהצלחה
                </Alert>
            </Snackbar>}

        </>
    )

}