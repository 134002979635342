import { useEffect, useState } from "react"
import { Box, Button, CircularProgress, IconButton } from "@mui/material"
import styles from './style'
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom'

export default function Categories({ changeTab, categories, onMount, loading }) {

    const navigate = useNavigate()
    useEffect(() => {
        changeTab(10)
        onMount()
    }, [])
    return (
        <Box sx={styles.main}>
            {loading ? <CircularProgress color="primary" sx={{ position: "absolute", top: "50%", left: "50%" }} /> :
                <><Box sx={{ fontSize: "1.7vw", fontWeight: "700" }} component="p">ניהול קטגוריות</Box>
                    <Box sx={styles.list}>
                        <Box sx={{
                            width: "100%",
                            ...styles.header
                        }}>
                            <Box
                                component="p"
                                sx={styles.headerText}
                            >
                                שם הקטגוריה
                            </Box>
                            <Box
                                component="p"
                                sx={styles.headerText}
                            >
                                שם הקטגוריה באנגלית
                            </Box>
                            <Box
                                component="p"
                                sx={styles.headerText}
                            >
                                סטטוס
                            </Box>
                            <IconButton
                                disableRipple
                                onClick={() => { navigate("/categories/new") }}
                                sx={{ px: 0, width: "33%" }}>
                                <AddIcon color="selected" />
                            </IconButton>
                        </Box>
                        {categories && categories.length > 0 &&
                            categories.map((item) => (
                                <Box key={item.id} sx={styles.prayer}>
                                    <Box sx={styles.wrap}>
                                        <Box component="p" sx={styles.name}>
                                            {item.subject}
                                        </Box>
                                        <Box component="p" sx={styles.name}>
                                            {item.subjectEn}
                                        </Box>
                                        <Box component="p" sx={styles.name}>
                                            {item.isActive == true ? "פעיל" : "לא פעיל"}
                                        </Box>
                                        <Box
                                            sx={{ width: "34%" }}
                                        >
                                            <IconButton
                                                disableRipple
                                                onClick={() => { navigate(`/categories/${item.id}`) }}
                                                sx={{ px: 0 }}>
                                                <EditIcon color="selected" />
                                            </IconButton>
                                        </Box>

                                    </Box>
                                </Box>

                            ))

                        }
                    </Box></>}
        </Box>
    )
}