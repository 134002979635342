import {
  createActionCreators,
  createActionTypes,
} from "../../utils/reduxUtils";

const typesActions = [
  "CREATE_EXPENSE_REQUEST",
  "CREATE_EXPENSE_SUCCESS",
  "CREATE_EXPENSE_FAILURE",

  "UPDATE_EXPENSE_REQUEST",
  "UPDATE_EXPENSE_SUCCESS",
  "UPDATE_EXPENSE_FAILURE",
  "SET_SUBMIT_SUCCESS",

  "GET_FUND_EXPENSES_REQUEST",
  "GET_FUND_EXPENSES_SUCCESS",
  "GET_FUND_EXPENSES_FAILURE",

  "EDIT_CATEGORY_REQUEST",
  "EDIT_CATEGORY_SUCCESS",
  "EDIT_CATEGORY_FAILURE",

  "DELETE_EXPENSES_REQUEST",
  "DELETE_EXPENSES_SUCCESS",
  "DELETE_EXPENSES_FAILURE",

  "DELETE_FUND_REQUEST",
  "DELETE_FUND_SUCCESS",
  "DELETE_FUND_FAILURE",
];
export const Actions = createActionCreators(typesActions);
export const ActionsTypes = createActionTypes(typesActions);
