import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  TableFooter,
  TableContainer,
  Button,
  CircularProgress,
} from "@mui/material";
import styles from "./style";
import { useNavigate, useLocation } from "react-router-dom";
import { useState } from "react";
import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function DataTable({
  fdata,
  headers,
  showData,
  onChange,
  page,
  path,
  onUpdate,
  isSubmitting,
  error,
  submitSuccess,
  disableClick,
  setOpen,
  setAmount,
  setDate,
  setupdate,
  setExpenseId,
  handleDelete,
  handleActionClick,
  buttonLable,
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedId, setSelectedId] = useState("");
  const [openDialog, setOpenDialog] = React.useState(false);

  let count = 0;
  let emptyRows = 0;
  let rowsPerPage = 10;
  const handleChangePage = (event, newPage) => {
    onChange(newPage);
  };
  let dataView = [];
  let data = fdata ? (fdata.length > 0 ? fdata : []) : [];
  if (data[0] && data[0].length > 0) {
    dataView = showData(data[0]);

    count = data[1];
    emptyRows = rowsPerPage - dataView.length;
  } else {
    count = 0;

    emptyRows = 10;
  }
  console.log("buttonLable : ", buttonLable);
  const handleclick = (row) => {
    if (row.hasOwnProperty("fundType")) {
      if (row.fundType === "mosad")
        navigate(`${location.pathname}/mosad/${row.id}`);
      if (row.fundType === "private")
        navigate(`${location.pathname}/${row.id}`);
    } else if (row.hasOwnProperty("status") && row.status === "finished") {
    }
    //   && row.status !== "finished"   its was in the if
    else if (row.hasOwnProperty("amount")) {
      setAmount(row.amount);
      setupdate(true);
      console.log(row.updatedAt);
      console.log(row.createdAt);
      setDate(row.createdAt);
      setOpen(true);
      setExpenseId(row.id);
    }
  };

  const handleClickOpenDialog = (id) => {
    setOpenDialog(true);
    setSelectedId(id);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  //     const handleActionClick = (row) => {
  //    setSelectedId(row.id);
  //   onUpdate(row.id);
  //     };
  const check = (row, header) => {
    let date = new Date(row[header.table]);
    let ddate =
      date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear();
    console.log(ddate);
    return ddate;
  };
  return (
    <React.Fragment>
      <TableContainer>
        <Table sx={{ width: "100%", mt: "2%" }}>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#D4D4D4", borderBottom: "none" }}>
              {headers.map((item, i) => (
                <TableCell
                  key={i}
                  sx={{
                    minWidth: { ...item.width },
                    borderRadius:
                      i == 0
                        ? "8px 0px 0px 0px "
                        : i + 1 === headers.length
                        ? " 0px 8px 0px 0px  "
                        : {},
                    ...styles.tabelHeader,
                  }}
                >
                  {item.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataView &&
              dataView.map((row, index) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  onClick={() => {
                    if (disableClick) return;
                    handleclick(row);
                  }}
                >
                  {headers.map((header, i) => (
                    <TableCell key={i} align="left" sx={styles.tabelCell}>
                      {header.table === "Action" ? (
                        <Button
                          variant="contained"
                          onClick={() => {
                            handleActionClick(row);
                          }}
                        >
                          {/* {isSubmitting && row.id === selectedId ? (
                          <CircularProgress
                            size={20}
                            sx={{ color: "#333333" }}
                          />
                        ) : (
                          "עדכן"
                        )} */}
                          {buttonLable}
                        </Button>
                      ) : header.table === "deletLabel" ? (
                        <IconButton
                          aria-label="delete"
                          onClick={() => {
                            handleClickOpenDialog(row.id);
                          }}
                        >
                          {isSubmitting && row.id === selectedId ? (
                            <CircularProgress
                              size={20}
                              sx={{ color: "#333333" }}
                            />
                          ) : (
                            <DeleteIcon />
                          )}
                        </IconButton>
                      ) : header.table === "createdAt" ||
                        header.table === "updtedAt" ? (
                        check(row, header)
                      ) : (
                        row[header.table]
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 34 * emptyRows }}>
                {headers.map((item, i) => (
                  <TableCell key={i} sx={styles.cell} />
                ))}
              </TableRow>
            )}
          </TableBody>
          <TableFooter sx={{ height: "auto !important", p: 1 }}>
            <TableRow>
              <TablePagination
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[]}
                count={count}
                page={count <= 0 ? 0 : page}
                onPageChange={handleChangePage}
                align="center"
                sx={styles.pagination}
                labelDisplayedRows={({ page }) => {
                  return `${
                    page == 0
                      ? dataView.length < 10
                        ? dataView.length
                        : 10
                      : 10 * (page + 1) > count
                      ? count
                      : 10 * (page + 1)
                  }/${count}`;
                }}
                showFirstButton={true}
                showLastButton={true}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{""}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            לאשר מחיקה לצמיתות?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}> ביטול </Button>
          <Button
            onClick={() => {
              handleDelete(selectedId);
              handleCloseDialog();
            }}
            autoFocus
          >
            מחק
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
