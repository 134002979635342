import axios from "axios";
import { retrieveAccessToken } from "../utils/authUtils";

const baseURL = process.env.REACT_APP_SERVER;

const axiosInstance = axios.create({ baseURL });

axiosInstance.interceptors.request.use(async (request) => {
  const accessToken = await retrieveAccessToken();
  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`;
  }
  return request;
});

export default axiosInstance;
