import { Box, Button, TextField, Snackbar, Alert, CircularProgress } from '@mui/material'
import { useFormik } from 'formik'
import styles from './style'
import { useState, useEffect } from 'react'
import theme from '../../themes/default'


export default function ForgotPassword({ error, submitSuccess, onSubmit, loading, onMount }) {

    const [openSnack, setOpenSnack] = useState(true)
   
    const handleSnackClose = () => {
        setOpenSnack(false)
    }

    useEffect(() => {
        onMount()
    }, [])

    useEffect(() => {
        setOpenSnack(true)
    }, [error, submitSuccess])

    const formik = useFormik({
        initialValues: {
            email: '',

        },
        validate: (values) => {
            const errors = {};
            if (!values.email) {
                errors.email = 'יש להזין מייל';
            } else if (
                !/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(values.email)

            ) {
                errors.email = 'כתובת מייל אינה תקינה';
            }
            return errors;
        },
        onSubmit: (values) => {
            onSubmit(values.email)
        },
    });

    return (
        <>

            <Box sx={styles.wrapper}>
                <Box sx={styles.title}
                >
                    <Box sx={styles.paragraph}>
                        כלל ישראל חסד ורחמים</Box>
                </Box>
                <Box
                    component="form"
                    onSubmit={formik.handleSubmit}
                    id="forgotPass"
                    sx={styles.loginForm}>

                    <Box
                        sx={{ width: "50%", height: "60%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-around" }}>
                        <Box sx={styles.connect}>
                            תהליך איפוס סיסמא</Box>
                        <TextField
                            sx={{ ...styles.details }}
                            id="email"
                            name="email"
                            variant="outlined"
                            type="email"
                            placeholder="כתובת מייל"
                            fullWidth
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                        />
                        <>
                            <Button
                                fullWidth
                                variant="contained"
                                form="forgotPass"
                                type="submit"
                                sx={styles.submit}
                            >
                                {loading ? <CircularProgress size={26} sx={{ color: theme.palette.text.primary }} /> : "שליחה"}
                            </Button>
                        </>
                    </Box>




                </Box>


            </Box >
            {
                error &&
                (<Snackbar
                    open={openSnack}
                    autoHideDuration={6000}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    onClose={handleSnackClose}>
                    <Alert
                        onClose={handleSnackClose}
                        variant="filled"
                        severity="error"
                        sx={{ width: '100%' }}>
                        אירעה שגיאה.נסה שוב
                    </Alert>

                </Snackbar>)}

            {submitSuccess && <Snackbar
                open={openSnack}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }}
                onClose={handleSnackClose}>
                <Alert
                    onClose={handleSnackClose}
                    variant="filled"
                    severity="success"
                    sx={{ width: '100%' }}>
                    בדוק את תיבת הדואר הנכנס</Alert>
            </Snackbar>}

        </>
    )


}