import { Box, Button, TextField, CircularProgress, Snackbar, Alert } from "@mui/material"
import styles from "./style"
import theme from "../../themes/default"
import { useFormik } from 'formik';
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";


export default function RessetPassword({ loading, onSubmit, onMount, error, submitSuccess }) {

    const [openSnack, setOpenSnack] = useState(true);
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        setOpenSnack(true)
    }, [error, submitSuccess])

    const details = [
        {
            name: "כתובת מייל",
            id: "email"

        },
        {
            name: "סיסמא",
            id: "password"

        },
        {
            name: "אישור סיסמא",
            id: "confirmPassword"

        }
    ]


    const validateInputs = (values) => {
        const errors = {};
        if (!values.email) {
            errors.email = 'יש להזין מייל';
        } else if (
            !/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(values.email)

        ) {
            errors.email = 'כתובת מייל אינה תקינה';
        }
        if (!values.password) {
            errors.password = 'יש להזין סיסמא על מנת להתחבר';
        } else if (
            values.password.length < 8
        ) {
            errors.password = 'סיסמא קטנה מ8 תווים';
        }
        if (!values.confirmPassword) {
            errors.confirmPassword = 'יש לאשר סיסמא';
        } else if (
            values.confirmPassword != values.password
        ) {
            errors.confirmPassword = 'סיסמא לא זהה';
        }
        return errors;
    }


    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            confirmPassword: ''

        },
        validate: validateInputs,
        onSubmit: (values) => {
            let email = values.email;
            let password = values.password;
            let confirmPassword = values.confirmPassword
            let resetCode = searchParams.get("guid");
            onSubmit({ email, password, resetCode, confirmPassword })
        },
    });

    const handleSnackClose = () => {
        setOpenSnack(false)
    }



    return (
        <>
            <Box sx={styles.wrapper}>
                <Box sx={styles.title}
                >
                    <Box sx={styles.paragraph}>
                        כלל ישראל חסד ורחמים</Box>
                </Box>
                <Box
                    component="form"
                    onSubmit={formik.handleSubmit}
                    id="loginForm"
                    sx={styles.loginForm}>
                    <Box sx={styles.connect}>
                        איפוס סיסמא</Box>
                    <Box
                        sx={{ width: "50%", height: "70%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between" }}>
                        {details.map((item) => (
                            <TextField
                                key={item.id}
                                sx={{ ...styles.details }}
                                id={item.id}
                                name={item.id}
                                variant="outlined"
                                type={item.id === "email" ? "email" : "password"}
                                placeholder={item.name}
                                fullWidth
                                value={formik.values[item.id]}
                                onChange={formik.handleChange}
                                error={formik.touched[item.id] && Boolean(formik.errors[item.id])}
                                helperText={formik.touched[item.id] && formik.errors[item.id]}
                            />
                        ))}
                        <>
                            <Button
                                fullWidth
                                variant="contained"
                                form="loginForm"
                                type="submit"
                                sx={styles.submit}
                            >
                                {loading ? <CircularProgress size={26} sx={{ color: theme.palette.text.primary }} /> : "אפס סיסמא"}
                            </Button>

                            {submitSuccess &&
                                <Link
                                    to="/login"
                                    style={{
                                        textAlign: "right",
                                        alignContent: "right",
                                        color: "blue",
                                        alignSelf: "flex-start",
                                        fontSize: "1.2vw"
                                    }}
                                >
                                    התחבר </Link>
                            }


                        </>
                    </Box>




                </Box>

            </Box >
            {error &&
                (<Snackbar
                    open={openSnack}
                    autoHideDuration={6000}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    onClose={handleSnackClose}>
                    <Alert
                        onClose={handleSnackClose}
                        variant="filled"
                        severity="error"
                        sx={{ width: '100%' }}>
                        אירעה שגיאה. נסה שוב</Alert>

                </Snackbar>)
            }

            {submitSuccess && <Snackbar
                open={openSnack}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }}
                onClose={handleSnackClose}>
                <Alert
                    onClose={handleSnackClose}
                    variant="filled"
                    severity="success"
                    sx={{ width: '100%' }}>
                    סיסמא שונתה בהצלחה </Alert>
            </Snackbar>}
        </>
    )
}