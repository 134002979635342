import theme from "../../themes/default";
const styles = {
    wrap: {
        width: "90%",
        display: "flex",
        flexDirection: "column",
        py: 3,
        px: 5,
        boxSizing: "border-box",
    },
    actions: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        mb: "2%"
    },
    createButton: {
        // width: "12%",
        borderRadius: 0,
        p: 1,
        mr: 4,
        fontSize: "1vw",
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
        },


    }
};

export default styles;