import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import {
  Box,
  FormControl,
  Autocomplete,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
import { useFormik } from "formik";
import { useState, useEffect } from "react";
import {
  fetchCities,
  fetchBankCodes,
  fetchCategories,
} from "../../utils/autoComplete";
import styles from "./style";
import UploadFileIcon from "@mui/icons-material/UploadFile";

export default function EditInstituteFormDialog({
  openEditFormDialog,
  setOpenEditFormDialog,
  id,
  fund,
  onMount,
  loading,
  onSubmit,
  isSubmitting,
  submitSuccess,
}) {
  const [cities, setCities] = useState([]);
  const [codes, setCodes] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [submitDate, setSubmitDate] = useState();
  const [files, setFiles] = useState();

  const details = [
    {
      name: "שם עמותה",
      id: "mosadName",
    },
    {
      name: "מס' עמותה",
      id: "mosadNum",
    },
    {
      name: "טלפון",
      id: "phone",
    },
    {
      name: "כתובת מייל",
      id: "email",
    },
    {
      name: "עיר",
      id: "city",
    },
    {
      name: "רחוב",
      id: "street",
    },
    {
      name: "מס' בית",
      id: "houseNo",
    },
    {
      name: "קטגוריה",
      id: "category",
    },
    {
      name: "פרטי המקרה",
      id: "description",
    },
    {
      name: "הערות",
      id: "comments",
    },
  ];

  const bankDetails = [
    {
      name: "בנק",
      id: "bankName",
      width: { xs: "100%", md: "25%" },
    },
    {
      name: "מספר בנק",
      id: "code",
      width: { xs: "100%", md: "13%" },
    },
    {
      name: "מספר חשבון",
      id: "accountNumber",
      width: { xs: "100%", md: "20%" },
    },
    {
      name: "סניף",
      id: "branch",
      width: { xs: "100%", md: "10%" },
    },
    {
      name: 'ע"ש',
      id: "ownerName",
      width: { xs: "100%", md: "20%" },
    },
  ];

  const mosadFiles = [
    {
      name: "טופס בקשה",
      id: "fileRequest",
      width: { xs: "100%", md: "25%" },
    },
    {
      name: "ניהול ספרים",
      id: "fileBookKeepingAuthorization",
      width: { xs: "100%", md: "13%" },
    },
    {
      name: "קובץ ניהול תקין",
      id: "fileProperManagement",
      width: { xs: "100%", md: "20%" },
    },
  ];

  const validateInputs = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = "יש להזין כתובת מייל";
    } else if (!/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(values.email)) {
      errors.email = "כתובת מייל אינה תקינה";
    }
    if (!values.mosadName) {
      errors.mosadName = "יש להזין שם עמותה";
    }
    if (!values.phone) {
      errors.phone = "יש להזין מס' טלפון";
    } else if (!/^[0-9]*$/.test(values.phone)) {
      errors.phone = "מספרים בלבד";
    } else if (values.phone.length != 10) {
      errors.phone = "מס' לא תקין";
    }
    if (!values.mosadNum) {
      errors.mosadNum = "יש להזין מספר עמותה";
    } else if (!/^[0-9]*$/.test(values.mosadNum)) {
      errors.mosadNum = "מספרים בלבד";
    }
    // else if (values.tz_num.length != 9) {
    //     errors.tz_num = "מס' לא תקין";
    // }
    if (!values.city) {
      errors.city = "יש לבחור עיר";
    }
    if (!values.street) {
      errors.street = "יש להזין שם רחוב";
    }
    if (!values.houseNo) {
      errors.houseNo = "יש  להזין מס' בית";
    } else if (!/^[0-9]*$/.test(values.houseNo)) {
      errors.houseNo = "מספרים בלבד";
    }
    if (!values.category) {
      errors.category = "יש לבחור קטגורית תמיכה";
    }
    if (!values.description) {
      errors.description = "יש להזין את פרטי המקרה";
    }
    if (!values.bankName) {
      errors.bankName = "יש להזין שם בנק";
    }
    if (!values.code) {
      errors.code = "יש להזין קוד בנק";
    }
    if (!values.accountNumber) {
      errors.accountNumber = "יש להזין מס' חשבון";
    } else if (!/^[0-9]*$/.test(values.accountNumber)) {
      errors.accountNumber = "מספרים בלבד";
    }
    if (!values.branch) {
      errors.branch = "יש להזין מס' סניף";
    } else if (!/^[0-9]*$/.test(values.branch)) {
      errors.branch = "מספרים בלבד";
    }
    if (!values.ownerName) {
      errors.ownerName = "יש להזין שם בעל החשבון' ";
    }
    if (!values.fileRequest) {
      errors.fileRequest = "לא נבחר קובץ";
    }
    if (!values.fileBookKeepingAuthorization) {
      errors.fileBookKeepingAuthorization = "לא נבחר קובץ";
    }
    if (!values.fileProperManagement) {
      errors.fileProperManagement = "לא נבחר קובץ";
    }
    if (!values.submitDate) {
      errors.submitDate = "בחר תאריך";
    }
    if (selectedCategory && selectedCategory.fileTypes) {
      selectedCategory.fileTypes.forEach(function (item) {
        if (files.hasOwnProperty(item.guid)) {
        } else if (!values[item.guid]) errors[item.guid] = "לא נבחר קובץ";
      });
    }

    return errors;
  };

  const handleselectedFile = (event) => {
    if (event.target.files.length > 0) {
      const file = URL.createObjectURL(event.target.files[0]);
      formik.setFieldValue(event.target.id, {
        url: file,
        file: event.target.files[0],
        name: event.target.files[0].name,
      });
      let appfiles = files;
      if (appfiles.hasOwnProperty(event.target.id)) {
        delete appfiles[event.target.id];
        setFiles(appfiles);
      }
    }
  };


  const resetFormAndState = () => {
    formik.resetForm({
      values: {
        mosadName: "",
        email: "",
        phone: "",
        mosadNum: "",
        city: null,
        street: "",
        houseNo: "",
        category: null,
        description: "",
        comments: "",
        bankName: null,
        code: "",
        branch: "",
        accountNumber: "",
        ownerName: "",
        submitDate: "",
        fileRequest: "",
        fileBookKeepingAuthorization: "",
        fileProperManagement: "",
      },
    });
  
    setCities([]);
    setCodes([]);
    setCategories([]);
    setSelectedCategory(null);
    setSubmitDate(null);
    setFiles(null);

  };


  useEffect(() => {
    fetchCities()
      .then((c) => setCities(c))
      .catch(console.error);
    fetchBankCodes()
      .then((c) => setCodes(c))
      .catch(console.error);
    fetchCategories('mosad')
      .then((c) => setCategories(c))
      .catch(console.error);
  }, []);

  useEffect(() => {
    if (id) {
      onMount(id);
    }
  }, [id, onMount]);

  useEffect(() => {
    if (fund) {
      if (fund.updatedAt) {
        let date = new Date(fund.updatedAt);
        let year = date.getFullYear();
        let month = `${date.getMonth() + 1}`.padStart(2, "0");
        let day = `${date.getDate()}`.padStart(2, "0");
        const ddate = [year, month, day].join("-");
        setSubmitDate(ddate);
      }
      if (
        fund.category &&
        fund.category.fileTypes &&
        fund.category.fileTypes.length > 0
      ) {
        if (fund.files && fund.files.length > 0) {
          let appfiles = {};
          fund.files.forEach(function (item) {
            appfiles = {
              ...appfiles,
              [item.type.guid]: { url: item.url, id: item.type.id },
            };
            formik.setFieldValue(item.type.guid, {
              url: item.url,
              id: item.type.id,
            });
          });
          setFiles(appfiles);
          setSelectedCategory(fund.category);
        }
      }
    }
  }, [fund]);

  const handleClose = () => {
    resetFormAndState();
    setOpenEditFormDialog(false);
  };

  const formik = useFormik({
    initialValues: {
      mosadName: fund ? fund.mosadName : "",
      email: fund ? fund.email : "",
      phone: fund ? fund.phone : "",
      mosadNum: fund ? fund.mosadNum : "",
      city: fund ? { שם_ישוב: fund.city } : null,
      street: fund ? fund.street : null,
      houseNo: fund ? fund.houseNo : "",
      category: fund ? fund.category : null,
      description: fund ? fund.description : "",
      comments: fund ? fund.comments : "",
      bankName: fund
        ? codes.find((t) => t.code === fund.bankAccount.code)
        : null,
      code: fund ? fund.bankAccount.code : "",
      branch: fund ? fund.bankAccount.branch : "",
      accountNumber: fund ? fund.bankAccount.accountNumber : "",
      ownerName: fund ? fund.bankAccount.ownerName : "",
      submitDate: fund ? submitDate : "",
      fileRequest: fund ? fund.fileRequest : "",
      fileBookKeepingAuthorization: fund
        ? fund.fileBookKeepingAuthorization
        : "",
      fileProperManagement: fund ? fund.fileProperManagement : "",
    },
    enableReinitialize: true,
    validate: validateInputs,
    onSubmit: (values) => {
      let files = {};
      if (values.category.fileTypes && values.category.fileTypes.length > 0) {
        values.category.fileTypes.forEach(function (item) {
          if (values[item.guid]) {
            files = { ...files, [item.guid]: values[item.guid] };
          }
        });
      }

      let details = {
        category: values.category.id,
        description: values.description,
        comments: values.comments,
        mosadName: values.mosadName,
        phone: values.phone,
        email: values.email,
        mosadNum: values.mosadNum,
        city: values.city["שם_ישוב"],
        street: values.street,
        houseNo: Number(values.houseNo),
        bankAccount: {
          code: values.code,
          branch: values.branch,
          accountNumber: values.accountNumber,
          ownerName: values.ownerName,
        },
        fileRequest: values.fileRequest,
        fileBookKeepingAuthorization: values.fileBookKeepingAuthorization,
        fileProperManagement: values.fileProperManagement,
        files: files,
      };
      onSubmit({ details: details, id: id });

      resetFormAndState();
    },
  });
  useEffect(() => {
    if (formik.values.bankName)
      formik.setFieldValue("code", formik.values.bankName.code);
    else {
      formik.setFieldValue("code", "");
    }
  }, [formik.values.bankName]);

  return (
    <div>
      <Dialog open={openEditFormDialog} onClose={handleClose} maxWidth="lg">
        <Box sx={styles.main}>
          <Box
            component="form"
            onSubmit={formik.handleSubmit}
            id="applicationForm"
            sx={styles.form}
          >
            <Box sx={styles.title}>עריכת קרן-כלל ישראל חסד ורחמים</Box>
            <Box sx={styles.subTitle}>עבור מוסד/ארגון</Box>
            <Box sx={styles.formDetails}>
              {details.map((item, index) => {
                if (item.id === "city")
                  return (
                    <React.Fragment key={item.id}>
                      <FormControl sx={styles.Autodetails}>
                        <Autocomplete
                          id={item.id}
                          name={item.id}
                          value={formik.values[item.id]}
                          onChange={(e, value) => {
                            formik.setFieldValue("city", value);
                          }}
                          options={cities}
                          loading={true}
                          loadingText="אין אופציות"
                          getOptionLabel={(type) => type["שם_ישוב"]}
                          isOptionEqualToValue={(option, value) =>
                            option._id === value._id
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder={item.name}
                              sx={styles.placeholder}
                              error={
                                formik.touched[item.id] &&
                                Boolean(formik.errors[item.id])
                              }
                              helperText={
                                formik.touched[item.id] &&
                                formik.errors[item.id]
                              }
                            />
                          )}
                        />
                      </FormControl>
                    </React.Fragment>
                  );
                else if (item.id === "category")
                  return (
                    <React.Fragment key={item.id}>
                      <FormControl sx={styles.Autodetails}>
                        <Autocomplete
                          id={item.id}
                          name={item.id}
                          value={formik.values[item.id]}
                          onChange={(e, value) => {
                            formik.setFieldValue("category", value);
                            setSelectedCategory(value);
                          }}
                          options={categories}
                          loading={true}
                          loadingText="אין אופציות"
                          getOptionLabel={(type) => type["subject"]}
                          isOptionEqualToValue={(option, value) =>
                            option._id === value._id
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder={item.name}
                              sx={styles.placeholder}
                              error={
                                formik.touched[item.id] &&
                                Boolean(formik.errors[item.id])
                              }
                              helperText={
                                formik.touched[item.id] &&
                                formik.errors[item.id]
                              }
                            />
                          )}
                        />
                      </FormControl>
                    </React.Fragment>
                  );

                return (
                  <TextField
                    key={item.id}
                    sx={{
                      ...styles.details,
                      mt: 6,
                      width: {
                        xs: "100%",
                        md:
                          item.id === "description" || item.id == "comments"
                            ? "100%"
                            : "23%",
                      },
                    }}
                    rows={
                      item.id === "description" || item.id === "comments"
                        ? 5
                        : 1
                    }
                    multiline
                    id={item.id}
                    name={item.id}
                    variant="outlined"
                    type={item.id}
                    label={item.name}
                    fullWidth
                    disabled={item.id === "mosadNum" ? true : false}
                    value={formik.values[item.id]}
                    onChange={formik.handleChange}
                    error={
                      formik.touched[item.id] && Boolean(formik.errors[item.id])
                    }
                    helperText={
                      formik.touched[item.id] && formik.errors[item.id]
                    }
                  />
                );
              })}
              <Box
                sx={{ display: "flex", flexDirection: "column", width: "100%" }}
              >
                <Box component="p" sx={styles.p}>
                  {"קבצים"}
                </Box>

                {selectedCategory && selectedCategory.fileTypes && (
                  <Box component="p" sx={{ mt: 0, color: "red" }}>
                    סוגי קבצים מותרים: jpeg, jpg, png, pdf
                  </Box>
                )}
              </Box>
            </Box>
            <Box sx={styles.filesBox}>
              {selectedCategory &&
                selectedCategory.fileTypes &&
                selectedCategory.fileTypes.map((item, index) => (
                  <Box
                    key={"fileType-" + item.guid + "-" + index}
                    sx={{ display: "flex", my: 2 }}
                  >
                    <input
                      key={item.guid}
                      type="file"
                      name={item.guid}
                      id={item.guid}
                      onChange={handleselectedFile}
                      style={{ display: "none" }}
                    />
                    <label htmlFor={item.guid}>
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        endIcon={<UploadFileIcon />}
                      >
                        {item.label}
                      </Button>
                    </label>

                    {files && files[item.guid] && files[item.guid].url ? (
                      <Button
                        onClick={() => {
                          window.open(
                            files[item.guid].url,
                            "_blank",
                            "noopener,noreferrer"
                          );
                        }}
                      >
                        הצג קובץ
                      </Button>
                    ) : (
                      <p>
                        {" "}
                        {formik.values[item.guid]
                          ? formik.values[item.guid].name
                          : ""}
                      </p>
                    )}
                    {Boolean(formik.errors[item.guid]) && (
                      <FormHelperText error={true}>
                        {formik.errors[item.guid]}{" "}
                      </FormHelperText>
                    )}
                  </Box>
                ))}
            </Box>
            <Box sx={styles.bankBox}>
              <Box component="p" sx={styles.pBank}>
                פרטי חשבון בנק להעברת הכספים
              </Box>

              <Box sx={styles.bankDetails}>
                {bankDetails.map((item, index) => {
                  if (item.id === "bankName") {
                    return (
                      <React.Fragment
                        key={"bankDetail-fragment-" + item.id + "-" + index}
                      >
                        <FormControl
                          sx={{ ...styles.Autodetails, width: item.width }}
                        >
                          <Autocomplete
                            id={item.id}
                            name={item.id}
                            value={formik.values[item.id]}
                            onChange={(e, value) => {
                              formik.setFieldValue("bankName", value);
                            }}
                            options={codes}
                            loading={true}
                            loadingText="אין אופציות"
                            getOptionLabel={(type) => type.name}
                            isOptionEqualToValue={(option, value) =>
                              option.code === value.code
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder={item.name}
                                sx={styles.placeholder}
                                error={
                                  formik.touched[item.id] &&
                                  Boolean(formik.errors[item.id])
                                }
                                helperText={
                                  formik.touched[item.id] &&
                                  formik.errors[item.id]
                                }
                              />
                            )}
                          />
                        </FormControl>
                      </React.Fragment>
                    );
                  }
                  return (
                    <TextField
                      key={"bankDetail-textfield-" + item.id + "-" + index}
                      sx={{
                        ...styles.details,
                        mt: 6,
                        width: item.width,
                      }}
                      id={item.id}
                      name={item.id}
                      variant="outlined"
                      type={item.id}
                      label={item.name}
                      fullWidth
                      value={formik.values[item.id]}
                      onChange={
                        item.id === "code" ? () => {} : formik.handleChange
                      }
                      error={
                        formik.touched[item.id] &&
                        Boolean(formik.errors[item.id])
                      }
                      helperText={
                        formik.touched[item.id] && formik.errors[item.id]
                      }
                    />
                  );
                })}
              </Box>
            </Box>
            <Box sx={styles.filesBox}>
              {mosadFiles.map((item, index) => (
                <Box
                  key={"mosadFile-" + item.id + "-" + index}
                  sx={{
                    display: "flex",
                    mb: 1,
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <input
                    key={item.id}
                    type="file"
                    name={item.id}
                    id={item.id}
                    onChange={handleselectedFile}
                    style={{ display: "none" }}
                  />
                  <p>{item.name}</p>
                  <label htmlFor={item.id}>
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      endIcon={<UploadFileIcon />}
                    >
                      {formik.values[item.id]
                        ? formik.values[item.id].name
                        : "בחר קובץ"}
                    </Button>
                  </label>

                  {fund && fund[item.id] && fund[item.id] ? (
                    <Button
                      onClick={() => {
                        window.open(
                          formik.values[item.id],
                          "_blank",
                          "noopener,noreferrer"
                        );
                      }}
                    >
                      הצג קובץ
                    </Button>
                  ) : (
                    <p>
                      {" "}
                      {formik.values[item.id]
                        ? formik.values[item.id].name
                        : ""}
                    </p>
                  )}
                  {formik.touched[item.id] &&
                    Boolean(formik.errors[item.id]) && (
                      <FormHelperText error={true}>
                        {formik.errors[item.id]}{" "}
                      </FormHelperText>
                    )}
                </Box>
              ))}
            </Box>
            <Box sx={styles.submit}>
              <TextField
                sx={{
                  ...styles.details,
                  mb: { xs: 2, md: 0 },
                }}
                id="submitDate"
                name="submitDate"
                variant="outlined"
                type="date"
                label="תאריך"
                InputLabelProps={{ shrink: true }}
                value={formik.values.submitDate}
                onChange={formik.handleChange}
                error={
                  formik.touched.submitDate && Boolean(formik.errors.submitDate)
                }
                helperText={
                  formik.touched.submitDate && formik.errors.submitDate
                }
              />
              <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
                form="applicationForm"
                sx={styles.sButton}
              >
                {isSubmitting ? (
                  <CircularProgress size={26} sx={{ color: "#000000" }} />
                ) : (
                  "שמור"
                )}
              </Button>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </div>
  );
}
