import {
  createActionCreators,
  createActionTypes,
} from "../../utils/reduxUtils";

const typesActions = [
  "FETCH_FUNDS_REQUEST",
  "FETCH_FUNDS_SUCCESS",
  "FETCH_FUNDS_FAILURE",

  "SINGLE_FUND_REQUEST",
  "SINGLE_FUND_SUCCESS",
  "SINGLE_FUND_FAILURE",

  "EDIT_FUND_REQUEST",
  "EDIT_FUND_SUCCESS",
  "EDIT_FUND_FAILURE",

  "FUND_EXPENSES_REQUEST",
  "FUND_EXPENSES_SUCCESS",
  "FUND_EXPENSES_FAILURE",

  "NEW_APPLICATION_REQUEST",
  "NEW_APPLICATION_SUCCESS",
  "NEW_APPLICATION_FAILURE",

  "RESET_FUND_DATA",

  "DELETE_FUND_REQUEST",
  "DELETE_FUND_SUCCESS",
  "DELETE_FUND_FAILURE",
];
export const Actions = createActionCreators(typesActions);
export const ActionsTypes = createActionTypes(typesActions);
