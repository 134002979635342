import theme from "../../themes/default";
const styles = {
    details: {
        "& .MuiOutlinedInput-root": {
            background: "white",
            "& > fieldset": {
                border: "1px solid #797979",
            },
            "& input::placeholder": {
                fontSize: { xs: "5px", md: "1.2vw" },
                color: " #545353",
                opacity: 1,


            },
            // height: "8vh",
        },

    },
    Autodetails: {
        // width: "100%",
        "& .MuiOutlinedInput-root": {
            background: "white",

            "& > fieldset": {
                border: "1px solid #797979",

            },



        },
        width: { xs: "100%", md: "23%" },
        mt: 6
    },
    placeholder: {
        "& input::placeholder": {
            fontSize: { md: "1.2vw" },
            color: " #545353",
            opacity: 1,


        },
    },
    main: {
        display: "flex",
        justifyContent: "center",
        // width: "100%",
        p: 6,
        boxSizing: "border-box"
    },
    form: {
        // width: "70%",
        backgroundColor: "#f2f2f2",
        display: "flex",
        flexDirection: "column",
        p: 4,
        alignItems: "center",
        border: `10px solid ${theme.palette.primary.main} `
    },
    title: {
        color: theme.palette.text.primary,
        fontSize: { md: "2.3vw" },
        fontWeight: 700
    },
    subTitle: {
        color: theme.palette.text.primary,
        fontSize: { md: "1.5vw" },
    },
    formDetails: {
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
        px: { xs: 1, md: 5 },
        pb: 0
    },
    p: {
        fontSize: { md: "1.4vw" },
        fontWeight: 500,
        color: theme.palette.text.primary,
        mr: "auto",
        mt: 4,
        mb: 0
    },
    filesBox: {
        display: "flex",
        flexDirection: "column",
        mr: "auto",
        px: { xs: 1, md: 5 },
        width: "100%",
        boxSizing: "border-box"
    },
    bankBox: {
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        px: { xs: 1, md: 5 },
        boxSizing: "border-box",
        flexDirection: "column"
    },
    pBank: {
        fontSize: { md: "1.4vw" },
        fontWeight: 500,
        color: theme.palette.text.primary,
        textAlign: "center",
        mt: 4
    },
    bankDetails: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap"
    },
    submit: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        justifyContent: "space-between",
        px: { xs: 1, md: 5 },
        boxSizing: "border-box",
        flexDirection: { xs: "column", md: "row" },
        mt: 3
    },
    sButton: {
        ml: "auto",
        fontWeight: 700,
        fontSize: { md: "1.3vw" },
        width: { xs: '100%', md: "25%" }
    },
    signBox: {
        boxSizing: "border-box",
        width: "100%",
        my: 3,
        display: "flex",
        flexDirection: "column",
        px: { xs: 1, md: 5 },
        height: { xs: 200, md: 300 }

    }

};

export default styles;