import { Box, Button, CircularProgress } from "@mui/material";
import styles from "./style";
import SearchTextField from "../../components/searchTextField/SearchTextField";
import DataTable from "../../components/DataTable";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

export default function Applications({
  onMount,
  submitSearch,
  clearSearch,
  loading,
  data,
  filter,
  changeTab,
  status,
  submitSuccess,
  onDelete,
  onUpdate,
}) {
  let filterSearch = "";
  const [page, setPage] = useState(0);
  const navigate = useNavigate();
  const [sortedTable, setsortedTable] = useState(data);
  // const [searchParams, setSearchParams] = useSearchParams();
  const [selectedId, setSelectedId] = useState("");

  const onChangePagination = (newPage) => {
    onMount({ page: newPage, filter: filter, status: status });
    setPage(newPage);
  };
  const handleDeleteClick = (id) => {
    onDelete(id);
  };

  useEffect(() => {
    setsortedTable(data);
  }, [data]);

  useEffect(() => {
    if (status) onMount({ page: 0, filter: filter, status: status });
    changeTab(4);
  }, [status]);

  useEffect(() => {
    onMount({ page: 0, filter: filter, status: status });
  }, [filter]);

  useEffect(() => {
    if (submitSuccess) onMount({ page: 0, filter: filter, status: status });
  }, [submitSuccess]);
  const handleAction = (row) => {
    setSelectedId(row.id);
    onUpdate(row.id);
  };

  const showData = (data) => {
    let showtable = [];
    let date;
    let ddate;

    if (data.length > 0) {
      data.map((item, index) => {
        // date = new Date(item.createdAt);
        // ddate =
        //   date.getDate() +
        //   "." +
        //   (date.getMonth() + 1) +
        //   "." +
        //   date.getFullYear();
        showtable.push({ ...item });
        showtable[index] = {
          ...showtable[index],
          fundName:
            item.fund?.fundType === "mosad"
              ? item.fund?.mosadName
              : item.fund?.firstName + " " + item.fund?.lastName,
          categoryName: item.category?.subject,
          // cityName: item.dependent.city,
          // createdAt: new Date(item.createdAt),
          statusLabel:
            item.status === "draft"
              ? "טיוטה"
              : item.status === "masav"
              ? 'ממתין למס"ב'
              : "הושלם",
        };
      });
    }
    return showtable;
  };
  const action =
    status === "draft"
      ? {
          name: 'העבר לממתינים למס"ב',
          width: "10%",
          table: "Action",
        }
      : {};
  let headers = [
    {
      name: "שם הקרן",
      width: "15%",
      table: "fundName",
    },
    {
      name: "סכום",
      width: "10%",
      table: "amount",
    },
    {
      name: "תאריך",
      width: "10%",
      table: "createdAt",
    },
    {
      name: "סטטוס הוצאה",
      width: "10%",
      table: "statusLabel",
    },
    action,
    {
      name: "מחק",
      width: "10%",
      table: "deletLabel",
    },
  ];

  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Box sx={styles.wrap}>
        <Box sx={styles.actions}>
          <SearchTextField
            onSearch={() => {
              submitSearch(filterSearch);
            }}
            onEmptySearch={() => {
              clearSearch();
            }}
            width="30%"
            setSearch={(item) => {
              filterSearch = item;
            }}
            placeholder="שם המבקש"
          />
        </Box>
        <Box>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {loading ? (
              <CircularProgress
                color="primary"
                sx={{ position: "absolute", top: "50%", left: "50%" }}
              />
            ) : (
              <DataTable
                fdata={sortedTable}
                showData={showData}
                headers={headers}
                onChange={onChangePagination}
                page={page}
                disableClick={true}
                handleDelete={handleDeleteClick}
                handleActionClick={handleAction}
                buttonLable={"העבר למסב"}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
