import { connect } from "../../utils/reduxUtils";
import Component from "./SinglePrivateFund";
import { Actions } from "../../pages/actions";
import * as ExpensesSelector from "../../modules/expenses/selectors";
import * as FundsSelector from "../../modules/funds/selectors";

const mapDispatchToProps = {
  getExpenses: Actions.GET_FUND_EXPENSES,
  onMount: Actions.SINGLE_FUND_PAGE_MOUNTED,
  changeTab: Actions.ACTIVE_TAB_CHANGED,
  getFund: Actions.GET_SINGLE_FUND,
  onDelete: Actions.DELETE_EXPENSE,
  setSubmitSuccess: Actions.SET_SUBMIT_SUCCESS,
};

const mapStateToProps = (state) => {
  return {
    data: ExpensesSelector.expenses(state),
    filter: ExpensesSelector.filter(state),
    loading: ExpensesSelector.isLoading(state),
    error: ExpensesSelector.error(state),
    submitSuccess: ExpensesSelector.submitSuccess(state),
    isSubmitting: ExpensesSelector.isSubmitting(state),
    fund: FundsSelector.fund(state),
    submitFundSuccess: FundsSelector.submitSuccess(state),
    submitFundError: FundsSelector.error(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
