import {
    Box, TextField, FormControl, Select,
    MenuItem, InputLabel, Autocomplete, Button,
    CircularProgress, Snackbar, Alert
} from "@mui/material"
import theme from "../../themes/default"
import styles from './style'
import { useFormik } from "formik"
import { useEffect, useState } from "react"
import moment from "moment";
import axios from "axios"


export default function Reports({ onMount, changeTab, categories, onSubmit, isSubmitting, errorMessage }) {

    const [openSnack, setOpenSnack] = useState(true)

    useEffect(() => {
        onMount();
        changeTab(9);
    }, [])

    useEffect(() => {
        setOpenSnack(true)
    }, [errorMessage])


    const handleSnackClose = () => {
        setOpenSnack(false)
    }

    const formik = useFormik({
        initialValues: {
            name: '',
            category: null,
            status: [],
            idNum: '',
            fromDate: '',
            toDate: ''


        },
        validate: validateInputs,
        onSubmit: (values) => {
            let data = {};
            if (values.firstName || values.lastName) {
                if (values.firstName && values.lastName) {
                    data = { ...values, name: values.firstName + " " + values.lastName }
                }
                else if (!values.firstName && values.lastName) {
                    data = { ...values, name: values.lastName }
                }
                else if (values.firstName && !values.lastName) {
                    data = { ...values, name: values.firstName }
                }
                delete data['firstName']
                delete data['lastName']
            }
            else {
                data = { ...values }
            }

            let filters = ''
            for (var key in data) {
                if (data[key]) {
                    if (key === "status") {
                        if (data[key].length > 0) {
                            data[key].forEach(function (item) {
                                filters = `${filters}` + `${filters === '' ? '' : '&'}status[]=${item}`
                            })
                        }

                    }
                    else if (key === "category") {
                        filters = `${filters}` + `${filters === '' ? '' : '&'}category=${data[key]["id"]}`
                    }
                    else if (key === "name") {
                        filters = `${filters}` + `${filters === '' ? '' : '&'}name=${data[key]}`
                    }
                    else
                        filters = `${filters}` + `${filters === '' ? '' : '&'}${key}=${data[key]}`
                }
            }
            onSubmit(filters)
        },
    });

    const handleStatusChange = (event) => {
        const {
            target: { value },
        } = event;
        formik.setFieldValue(
            // On autofill we get a stringified value.
            "status", typeof value === 'string' ? value.split(',') : value,
        );
    };


    return (
        <>
            <Box sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                <Box component="p" sx={{ fontSize: "1.4vw", color: theme.palette.text.primary, fontWeight: 700 }}>הצגה וייצוא דו"חות</Box>
                <Box
                    component="form"
                    id="exportCSV"
                    onSubmit={formik.handleSubmit}
                    sx={{
                        width: "100%", p: "2vw", boxSizing: "border-box", display: "flex",
                        justifyContent: "space-around", flexWrap: "wrap"
                    }}>
                    <TextField
                        sx={{
                            ...styles.details,
                            mt: 6,
                            width: "20%"
                        }}
                        id="name"
                        name="name"
                        variant="outlined"
                        // placeholder={item.name}
                        label="שם קרן"
                        InputLabelProps={{ shrink: true }}
                        value={formik.values.name}
                        onChange={formik.handleChange}
                    />
                    <TextField
                        sx={{
                            ...styles.details,
                            mt: 6,
                            width: "20%"
                        }}
                        id="idNum"
                        name="idNum"
                        variant="outlined"
                        // placeholder={item.name}
                        label="ת.ז/מספר עמותה"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        value={formik.values.idNum}
                        onChange={formik.handleChange}
                        error={formik.touched.idNum && Boolean(formik.errors.idNum)}
                        helperText={formik.touched.idNum && formik.errors.idNum}
                    />
                    <FormControl sx={{ mt: 6, width: "20%" }}>

                        <Autocomplete
                            id="category"
                            name="category"
                            value={formik.values.category}
                            onChange={(e, value) => formik.setFieldValue("category", value)}
                            options={categories}
                            // loading={true}
                            // loadingText="אין אופציות"
                            getOptionLabel={type => type.subject}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            renderInput={(params) => <TextField {...params}
                                label="קטגוריה"
                            />}
                        />
                    </FormControl>
                    <Box sx={{ mt: 6, width: "30%", display: "flex", justifyContent: "space-between" }} >
                        <TextField
                            sx={{
                                ...styles.details,
                                width: "48%"
                            }}
                            id="fromDate"
                            name="fromDate"
                            variant="outlined"
                            type="date"
                            // placeholder={item.name}
                            label="מתאריך"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            value={formik.values.fromDate}
                            onChange={formik.handleChange}
                            error={formik.touched.fromDate && Boolean(formik.errors.fromDate)}
                            helperText={formik.touched.fromDate && formik.errors.fromDate}
                        />
                        <TextField
                            sx={{
                                ...styles.details,
                                width: "48%"
                            }}
                            id="toDate"
                            name="toDate"
                            variant="outlined"
                            type="date"
                            // placeholder={item.name}
                            label="עד תאריך"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            value={formik.values.toDate}
                            onChange={formik.handleChange}
                            error={formik.touched.toDate && Boolean(formik.errors.toDate)}
                            helperText={formik.touched.toDate && formik.errors.toDate}
                        />
                    </Box>
                </Box>
                <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    form="exportCSV"
                    sx={{ width: "20vw", fontSize: "1.2vw", fontWeight: 600, borderRadius: 0 }}
                >
                    {isSubmitting ? <CircularProgress size={28} sx={{ color: "#ffffff" }} /> : "ייצוא נתונים לקובץ"}

                </Button>
            </Box >
            {errorMessage &&
                (<Snackbar
                    open={openSnack}
                    autoHideDuration={6000}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    onClose={handleSnackClose}>
                    <Alert
                        onClose={handleSnackClose}
                        variant="filled"
                        severity={errorMessage === "אירעה תקלה בלתי צפויה.נסה שנית" ? "error" : "info"}
                        sx={{ width: '100%' }}>
                        {errorMessage}</Alert>

                </Snackbar>)}
        </>
    )
}

const validateInputs = (values) => {
    const errors = {};


    if (values.idNum && !/^[0-9]*$/.test(values.idNum)) {
        errors.idNum = "מספרים בלבד";
    }
    else if (values.idNum && values.idNum.length != 9) {
        errors.idNum = "מס' לא תקין";
    }

    if (values.fromDate && !values.toDate) {
        errors.toDate = "יש להזין תאריך אחרון";
    }
    else if (!values.fromDate && values.toDate) {
        errors.fromDate = "יש להזין תאריך התחלה";
    }
    else if (values.fromDate && values.toDate) {
        if (moment(values.fromDate).isAfter(moment(values.toDate)))
            errors.fromDate = "תאריך התחלה מאוחר מתאריך סיום";
    }

    return errors;
}