import { useDispatch } from "react-redux"
import { Actions } from "../actions"
import { Box, Button, CircularProgress, Snackbar, Alert } from '@mui/material'
import styles from './style'
import SearchTextField from '../../components/searchTextField/SearchTextField'
import DataTable from '../../components/DataTable'
import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';


export default function PendingMasavExpenses({
    isSubmitting, exportApplications, onMount, submitSearch, clearSearch, loading, data, filter, error }) {
    const dispatch = useDispatch()
    let filterSearch = ''
    const [dataView, setDataView] = useState([])
    const [selection, setSelection] = useState([]);
    const [openSnack, setOpenSnack] = useState(false)
    const navigate = useNavigate()
    const role = sessionStorage.getItem("userRole")

    useEffect(() => {
        dispatch(Actions.ACTIVE_TAB_CHANGED(6))
        onMount({ filter: 'page=0&take=10' })
    }, [dispatch])

    const handleSnackClose = () => {
        setOpenSnack(false)
    }

    useEffect(() => {
        setOpenSnack(true)
    }, [error])

    useEffect(() => {
        if (data && data.length > 0) {
            let d = showData(data);
            setDataView(d)
        }
    }, [data])

    const showData = (data) => {

        let showtable = [];
        let date
        let ddate

        if (data[0].length > 0) {
            data[0].map((item, index) => {
                date = new Date(item.updatedAt)
                ddate = date.getDate() +
                    "." + (date.getMonth() + 1) +
                    "." + date.getFullYear();
                showtable.push({ ...item });
                showtable[index] = {
                    ...showtable[index],
                    fundName: item.fund.fundType==='mosad'?item.fund?.mosadName:item.fund?.firstName + " " + item.fund?.lastName,
                    dateLabel: ddate,
                }
            })
        }
        return showtable;
    }

    const columns = [
        { field: 'id', headerName: 'ID', width: 90 },
        {
            field: 'fundName',
            headerName: 'שם הקרן',
            width: 300,
        },
        {
            field: 'amount',
            headerName: 'סכום',
            type: 'number',
            width: 200,
            editable: true,
        },
        {
            field: 'dateLabel',
            headerName: 'תאריך',
            type: 'date',
            width: 200,
            editable: true,
        },
    ];

    const exportToMasav = () => {
        if (!selection || selection.length === 0)
            return;
        else exportApplications(selection)

    }

    return (
        <>
            <Box sx={{ display: "flex", justifyContent: "center", overflowY: "auto", height: "100vh" }}>
                <Box sx={styles.wrap}>
                    <Box sx={styles.actions}>
                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>

                            {role === "admin" || role === "officer" ? <Button
                                variant="contained"
                                color="primary"
                                sx={{ fontWeight: "700", width: "10vw" }}
                                onClick={exportToMasav}>
                                {isSubmitting ? <CircularProgress size={18} sx={{ color: "#000000" }} /> : "יצא נבחרים למסב"}
                            </Button> : <></>}
                        </Box>
                    </Box>
                    <Box>
                        <Box sx={{ width: "100%", display: "flex", height: "auto", justifyContent: "center", alignItems: "center", pt: 5 }}>
                            {loading ?
                                <CircularProgress color="primary" sx={{ position: "absolute", top: "50%", left: "50%" }} /> :
                                dataView && <DataGrid
                                    rows={dataView}
                                    columns={columns}
                                    sx={{
                                        boxShadow: 2,
                                        border: 2,
                                        borderColor: 'primary.light',
                                        '& .MuiDataGrid-cell:hover': {
                                            color: 'primary.main',
                                        },
                                        '& .MuiDataGrid-cell:hover': {
                                            color: 'primary.main',
                                        },
                                        columnHeader: { backgroundColor: "red" },
                                        mb: 5
                                    }}
                                    autoHeight
                                    onRowClick={(row) => { navigate(`/applications/pending-masav/${row.id}`) }}
                                    // pageSize={5}
                                    // rowsPerPageOptions={[5]}
                                    hideFooterPagination={true}
                                    checkboxSelection
                                    disableSelectionOnClick
                                    experimentalFeatures={{ newEditingApi: true }}
                                    onSelectionModelChange={(newSelection) => {
                                        setSelection(newSelection);
                                    }}
                                />
                            }
                        </Box>
                    </Box>

                </Box>
            </Box>
            {/* {error &&
                (<Snackbar
                    open={openSnack}
                    autoHideDuration={6000}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    onClose={handleSnackClose}>
                    <Alert
                        onClose={handleSnackClose}
                        variant="filled"
                        severity="error"
                        sx={{ width: '100%' }}>
                        אירעה תקלה בלתי צפויה.נסה שנית
                    </Alert>

                </Snackbar>)} */}

        </>
    )

}