import { connect } from "../../utils/reduxUtils"
import Component from "./EditInstituteFormDialog"
import { Actions } from "../../pages/actions"
import * as fundSelectors from "../../modules/funds/selectors"


const mapDispatchToProps = {
    onMount: Actions.SINGLE_FUND_PAGE_MOUNTED,
    onSubmit:Actions.EDIT_FUND_FORM_SUBMITTED,

};

const mapStateToProps = state => {
    return {
        error: fundSelectors.error(state),
        submitSuccess: fundSelectors.submitSuccess(state),
        isSubmitting:fundSelectors.isSubmitting(state),
        fund:fundSelectors.fund(state)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);



