import { ActionsTypes } from "./actions";
import { ActionTypes as PageActionsTypes } from "../../pages/actions";

const fundsReducer = (
  state = {
    funds: [],
    fund: null,
    loading: false,
    error: false,
    isValidate: false,
    submitSuccess: false,
    filter: "",
    funsExpenses: null,
    isSubmitting: false,
  },
  action
) => {
  let newState;
  let { payload, type } = action;
  console.log(type);
  switch (type) {
    case ActionsTypes.FETCH_FUNDS_REQUEST:
      newState = { ...state, loading: true, funds: null };
      break;
    case ActionsTypes.FETCH_FUNDS_SUCCESS:
      newState = { ...state, loading: false, funds: payload };
      break;
    case ActionsTypes.FETCH_FUNDS_FAILURE:
      newState = { ...state, loading: false, error: true };
      break;
    case PageActionsTypes.FILTER_SEARCH_FUNDS_SUBMITTED:
      newState = { ...state, filter: `&search=${payload}` };
      break;
    case PageActionsTypes.FILTER_SEARCH_FUNDS_CLEARED:
      newState = { ...state, filter: "" };
      break;
    case ActionsTypes.SINGLE_FUND_REQUEST:
      newState = { ...state, loading: true, fund: null };
      break;
    case ActionsTypes.SINGLE_FUND_SUCCESS:
      newState = { ...state, loading: false, fund: payload };
      break;
    case ActionsTypes.SINGLE_FUND_FAILURE:
      newState = { ...state, loading: false };
      break;
    case ActionsTypes.EDIT_FUND_REQUEST:
      newState = {
        ...state,
        isSubmitting: true,
        error: false,
        submitSuccess: false,
      };
      break;
    case ActionsTypes.EDIT_FUND_FAILURE:
      newState = { ...state, isSubmitting: false, error: true };
      break;
    case ActionsTypes.EDIT_FUND_SUCCESS:
      newState = { ...state, isSubmitting: false, submitSuccess: true };
      break;
    case PageActionsTypes.SINGLE_FUND_PAGE_MOUNTED:
      newState = {
        ...state,
        isSubmitting: false,
        submitSuccess: false,
        error: false,
      };
      break;
    case ActionsTypes.FUND_EXPENSES_REQUEST:
      newState = { ...state, loading: true, funsExpenses: null };
      break;
    case ActionsTypes.FUND_EXPENSES_SUCCESS:
      newState = { ...state, loading: false, funsExpenses: payload };
      break;
    case ActionsTypes.FUND_EXPENSES_FAILURE:
      newState = { ...state, loading: false };
      break;
    case ActionsTypes.NEW_APPLICATION_REQUEST:
      newState = {
        ...state,
        isSubmitting: true,
        error: false,
        submitSuccess: false,
      };
      break;
    case ActionsTypes.NEW_APPLICATION_SUCCESS:
      newState = {
        ...state,
        isSubmitting: false,
        error: false,
        submitSuccess: true,
      };
      break;
    case ActionsTypes.NEW_APPLICATION_FAILURE:
      newState = {
        ...state,
        isSubmitting: false,
        error: true,
        submitSuccess: false,
      };
      break;
    case ActionsTypes.RESET_FUND_DATA:
      console.log("IM in the reducer");
      newState = { ...state, fund: null };
      break;
    case ActionsTypes.DELETE_FUND_SUCCESS:
      console.log("in the reducer delete success");
      newState = { ...state, submitSuccess: true };
      break;

    default:
      newState = state;
  }
  return newState;
};
export default fundsReducer;
