const style = {
    root: {
        display: "flex",
        maxWidth: "100vw",
        overflowX: "hidden",
        justifyContent: "flex-start"
    },
    content: {
        flexGrow: 1,
        padding: 0,
        ml: "14%",
        // mr: 0,
        maxHeight: '100vh',
        overflowY: 'auto',
        overflowX: "hidden",
    },

};

export default style;
