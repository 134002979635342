import theme from "../../themes/default";

const style = {
    formControl: {
        position: 'relative',
        // Add additional styles as needed
      },
      inputLabel: {
        position: 'absolute',
        // top: '-8px', // Adjust this value to move the label up or down
        left: '-6px', // Adjust this value to move the label left or right
        backgroundColor: "white", // Set the background color of the label
        paddingLeft: '8px', // Add some padding to the label
        paddingRight: '8px',
        zIndex: 1, // Ensure the label appears above the underline
      },
}





export default style;